import React from 'react'
import colors from '@utils/colors'

function Copyright() {
    return (
        <div style={ footerStyle } >
            Copyright © fcrlab.unime.it 2021
        </div>
    )
}

const footerStyle = {
    width: '100%',
    padding: '10px 0',
    display: "flex",
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-evenly',

    backgroundColor: colors.color1,
    color: colors.color3
}

export default Copyright
