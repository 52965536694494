import React, {Component} from 'react'
import Item from './Item'

class Menu extends Component {
    render() {
        let services = this.props.services.map( item => <Item 
                key={item.id} 
                id={item.id} 
                title={item.title} 
                icon={item.icon}
                selected={this.props.selected === item.id} 
                handleClick={this.props.handleClick} 
            /> )
        return (
            <div style={menuStyle}>
                <center><h3>Services</h3></center>
                {services}
            </div>
        )
    }
}

const menuStyle = {
    flex: '1',
    minWidth: '180px',
    background: 'white',
    minHeight: '500px'
}

export default Menu