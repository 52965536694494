import React from 'react'
import  { Redirect } from 'react-router-dom'
import { ContextConsumer } from '@utils/Context'
import Form from './Form'

function SignupPage() { 
    return (
        <ContextConsumer>
            {
                ({ isLogged }) => (
                    isLogged ? 
                        // eslint-disable-next-line
                        <Redirect to='/'  /> : 
                        <div style={{top: "200px", display: "flex", flexDirection: "column"}}>
                            <center><h1 style={{marginBottom: 0}}>Registration are disabled.</h1></center>
                            <Form />
                        </div>
                )
            }
        </ContextConsumer>
    )
}

export default SignupPage