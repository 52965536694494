import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import colors from '@utils/colors'
import {notify} from '@utils/notifications'
import config from '@utils/config'

var serverUrl = "http://christiansicari.it:8005"

class SelectedStep extends Component {
    state = {
        minutes: 5,
        method: 'telegram',
        telegram: ''
    }

    handleChange = (event) => {
        const { name, value, type, checked } = event.target
        type === "checkbox" ? this.setState({ [name]: checked }) : this.setState({ [name]: value })
    }

    submit = (event) => {
        let { minutes, telegram } = this.state,
            data = {
                user: {
                    _id: telegram,
                    id: sessionStorage.getItem("username")
                },
                city: 'Messina',
                type: "Bus",
                line: this.props.lineId,
                step: this.props.step._id,
                timeBefore: minutes
            }

        console.log(data)
        event.preventDefault();
        fetch(serverUrl + "/api/subscribe", {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(data => {
                if(data.status === 201)
                {
                    notify("Success!", "Subscription create.", "success", "top-center");
                    this.props.selectStep(null)
                }
                else
                    notify("Error", JSON.stringify('Error'), "warning", "top-center");
            })
    }

    componentDidMount() {
        let url = config.serverUrl + "/users/getUserInfo/" + sessionStorage.getItem('id') + "?fields=socials";
        this.setState({loading: true});
        fetch( url )
            .then(response => response.json())
            .then(data => {
                data = data.socials;
                if(data) this.setState({telegram: data.telegram || ""});
            })
    }

    render() {
        return (
            <div style={{ display: 'flex' }}>
                <div style={{ width: '50px', margin: '0 3px 0 2px', borderRight: '1px solid #cccccc' }}>
                    <button onClick={() => this.props.selectStep(null)} style={btnStyle}>{"<"}</button>
                </div>
                <div style={{ padding: '0 20px', textAlign: 'left', height: '400px' }}>
                    <h3>{this.props.step.name}</h3>
                    <form onSubmit={this.submit}>
                        <h4 style={{ marginBottom: '0' }}>Number of previous stops</h4>
                        <select name="minutes" onChange={this.handleChange} required>
                            <option value={5}>1</option>
                            <option value={10}>2</option>
                            <option value={15}>3</option>
                            <option value={20}>4</option>
                            <option value={30}>5</option>
                        </select>

                        <h4 style={{ marginBottom: '0' }}>Telegram Username</h4>
                        <input name="telegram" type="text" value={this.state.telegram} onChange={this.handleChange} />

                        <p>You have to start our <Link to="telegramBot">Telegram Bot</Link> in order to receive the notifications.</p>
                        <div style={{ textAlign: 'center' }}><button style={{ ...btnStyle, width: '80%' }}>Subscribe</button></div>
                    </form>
                </div>
            </div>
        )
    }
}

const btnStyle = {
    width: "30px",
    height: "30px",
    margin: "20px 0 0 0",
    background: colors.color2,
    border: "none",
    borderRadius: "5px",
    color: colors.color5,
    fontWeight: "bold",
    cursor: "pointer"
}

export default SelectedStep