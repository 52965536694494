import React, { Component } from 'react'
import Item from './Item'
import config from '@utils/config'

class TeamPage extends Component {
    state = {
        team: []
    }

    componentDidMount() {
        let url = config.serverUrl + "/team/FCRLab?fields=name,surname,avatar,socials,joiningDate,role";
        this.setState({ loading: true });
        fetch(url)
            .then(response => response.json())
            .then(data => {
                data.forEach(x => {
                    x.key = x._id
                })
                this.setState({ team: data, loading: false, })
            })
    }

    render() {
        let teams = this.state.team.map((item, index) => <Item key={index} content={item} />)
        return (
            <div style={divStyle}>
                {teams}
            </div>
        )
    }
}

const divStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center'
}

export default TeamPage