import React from 'react'
import Topic from './Topic'
import colors from '@utils/colors'

function Research() {
    return (
        <div style={{background: colors.color6, paddingTop: "15px"}}>
            <h1>RESEARCH TOPICS</h1>
            
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%'}}>
                <div style={{maxWidth: '1000px', textAlign:'center', padding: '0px 12px 20px 12px'}}>
                    <i>The mission of the research group is to put together the modern innovative Cloud Computing and Internet of Things (IoT) technologies in order to leverage new IoT Cloud solutions for eHealth and Smart Cities domains.</i>
                </div>
                <div style={{display: 'flex', flexWrap:'wrap', justifyContent: 'center', width: '100%', marginBottom: '42px'}}>
                    <Topic type={'microchip'} title={'IoT & Edge Computing'} />
                    <Topic type={'cloud'} title={'Cloud Computing'} />
                    <Topic type={'random'} title={'Osmotic Computing'} />
                    <Topic type={'database'} title={'Big Data'} />
                    <Topic type={'code-fork'} title={'Machine Learning'} />
                    <Topic type={'lock'} title={'Privacy and Security'} />
                    <Topic type={'chain'} title={'Blockchain'} />
                    <Topic type={'plus-square'} title={'Healthcare'} />
                    <Topic type={'building-o'} title={'Smart City'} />
                </div>
            </div>
        </div>
    )
}

export default Research