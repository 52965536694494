import React, { PureComponent } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

class CropImage extends PureComponent {
    state = {
        src: null,
        format: this.props.format || 'jpeg',
        crop: {
            unit: "%",
            width: this.props.startCrop || 1000,
            aspect: this.props.aspect || null
        }
    };

    onSelectFile = e => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener("load", () =>
                this.setState({ src: reader.result })
            );
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    // If you setState the crop in here you should return false.
    onImageLoaded = image => {
        this.imageRef = image;
        this.convertToBlob(image, "image")
        .then( (blob) => this.props.updateCropImage( {newfile: true, blob, image} ) )
    };

    onCropComplete = crop => {
        this.makeClientCrop(crop);
    };

    onCropChange = (crop, percentCrop) => {
        // You could also use percentCrop:
        //this.setState({ crop: percentCrop });
        this.setState({ crop });
    };

    async makeClientCrop(crop) {
        let format = this.state.format
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                "newCroppedImage."+format
            );
            console.log("CROROPE", croppedImageUrl)
            this.setState({ croppedImageUrl });
            this.getBlob( this.imageRef, crop, "newCroppedImage."+format)
            .then ( (blob) => this.props.updateCropImage( {newfile: true, blob, image: croppedImageUrl} ) )
        }
    }

    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width*scaleX;
        canvas.height = crop.height*scaleY;
        const ctx = canvas.getContext("2d");

        ctx.fillStyle = "white";
        ctx.fillRect(0,0,canvas.width, canvas.height);
        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width*scaleX,
            crop.height*scaleY,
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    //reject(new Error('Canvas is empty'));
                    console.error("Canvas is empty");
                    return;
                }
                blob.name = fileName;
                window.URL.revokeObjectURL(this.fileUrl);
                this.fileUrl = window.URL.createObjectURL(blob);
                resolve(this.fileUrl);
            }, "image/"+this.state.format);
        });
    }

    convertToBlob(image, fileName) {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        canvas.width = image.naturalWidth;
        canvas.height = image.naturalHeight;

        ctx.fillStyle = "white";
        ctx.fillRect(0,0,canvas.width, canvas.height);
        ctx.drawImage(
            image,
            0,
            0,
            image.naturalWidth,
            image.naturalHeight,
            0,
            0,
            image.naturalWidth,
            image.naturalHeight,
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    //reject(new Error('Canvas is empty'));
                    console.error("Canvas is empty");
                    return;
                }
                blob.name = fileName;
                resolve(blob);
            }, "image/"+this.state.format);
        });
    }

    getBlob(image, crop, fileName) {
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width*scaleX;
        canvas.height = crop.height*scaleY;
        const ctx = canvas.getContext("2d");

        ctx.fillStyle = "white";
        ctx.fillRect(0,0,canvas.width, canvas.height);
        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width*scaleX,
            crop.height*scaleY,
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    //reject(new Error('Canvas is empty'));
                    console.error("Canvas is empty");
                    return;
                }
                blob.name = fileName;
                resolve(blob);
            }, "image/"+this.state.format);
        });
    }

    render() {
        const { crop, src } = this.state;
        return (
            <div>
                <div>
                    <input type="file" onChange={this.onSelectFile} />
                </div>
                <br />
                {src && ( 
                    <ReactCrop
                        src={src}
                        crop={crop}
                        onImageLoaded={this.onImageLoaded}
                        onComplete={this.onCropComplete}
                        onChange={this.onCropChange}
                        imageStyle={{backgroundColor: 'rgba(255, 255, 255, 1)'}}
                    />
                )}
            </div>
        );
    }
}

export default CropImage