import React, { Component } from 'react'
import Section from '@common/Section'
import Form from './Form'
import SubscribeForm from './SubscribeForm'
import Map from './Map'
import comune from './images/comune.png'
import fiware from './images/fiware.png'
import mesmart from './images/mesmart.png'
import ponMetro from './images/ponMetro.png'
import Unime from './images/Unime.png'
var serverUrl = "http://christiansicari.it:8005"

class Berlino extends Component {
    constructor() {
        super();
        this.child = React.createRef();
        this.map = React.createRef();
    }
    state = {
        meansOfTransport: "",
        lines: [],
        path: null,
        selectedLine: -1
    }

    handleState = (state => {
        this.setState(state)
    })

    getLines = () => {
        fetch(serverUrl + "/api/lines", {
            method: 'GET',
            headers: { 'cache-control': 'no-cache' }
        })
            .then(response => response.json())
            .then(data => {
                this.setState({ lines: data.data })
            })
    }

    setTransport = (meansOfTransport) => {
        this.child.current.selectStep(null);
        this.setState({ meansOfTransport, lines: [], path: null, selectedLine: -1 })
    }

    setPath = (path) => {
        this.setState({ path })
    }

    setLine = (selectedLine) => {
        console.log("SET LINE", this.state.lines)
        if(this.state.lines){
            console.log(this.state.lines[selectedLine].bus)
            this.map.current.updateBus(this.state.lines[selectedLine].bus);
        }
        this.child.current.selectStep(null);
        this.setState({ selectedLine, path: null })
    }

    selectStep = (n) => {
        this.child.current.selectStep(n);
    }

    render() {
        let line = this.state.lines[this.state.selectedLine];
        return (
            <div>
                <div style={{ display: 'flex', width: '100%', background: '#dddddd' }}>
                    <div style={boxStyle}><img src={comune} alt="comune" style={{...logoStyle, maxWidth: "30%"}} /></div>
                    <div style={boxStyle}><img src={Unime} alt="Unime" style={{...logoStyle, maxWidth: "40%"}} /></div> 
                    <div style={boxStyle}><img src={ponMetro} alt="ponMetro" style={logoStyle} /></div> 
                    <div style={boxStyle}><img src={mesmart} alt="mesmart" style={{...logoStyle, maxWidth: "60%"}} /></div> 
                    <div style={boxStyle}><img src={fiware} alt="fiware" style={logoStyle} /></div> 
                </div>

                <div>
                    <Section content={[
                        {
                            content:
                                <Form
                                    setTransport={this.setTransport}
                                    setLine={this.setLine}
                                    selectedLine={this.selectedLine}
                                    getLines={this.getLines}
                                    state={this.state}
                                />, title: "MeSmart: An Innovative FIWARE-based Queue Management System", flex: "16", minWidth: "300px"
                        }
                    ]}
                    />
                    <Section content={[
                        {
                            content: <Map line={line} path={this.state.path} setPath={this.setPath} selectStep={this.selectStep} ref={this.map} />,
                            title: "Map", flex: "16", minWidth: "300px"
                        },
                        {
                            content:
                                <SubscribeForm
                                    line={line}
                                    selectedStep={this.state.selectedStep}
                                    selectStep={this.selectStep}
                                    handleState={this.handleState}
                                    ref={this.child}
                                />,
                            title: "Bus stops",
                            flex: "9",
                            minWidth: "300px"
                        }
                    ]} />
                </div>
            </div>
        )
    }
}

const logoStyle = {
    maxWidth: "50%",
    height: 'auto',
    width: 'auto'
}

const boxStyle = {
    flex: '1 1 auto',
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    padding: '10px',
}

export default Berlino