import React, { createContext } from 'react';
import config from '@utils/config'
import {notify} from '@utils/notifications'

export const Context = createContext({
    isLogged: false,
    isAdmin: true,
    isFcrlab: true,
    username: "",
    login: () => {},
});

export class ContextProvider extends React.Component {
    login = (username, password, remember) => {

        fetch(config.serverUrl + '/users/login', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify( {username,password} )
        })
        .then( (res) => { return res.json() } )
        .then( (res) => {
            if(res.access_token)
            {
                console.log(res)
                sessionStorage.setItem('username', username);
                sessionStorage.setItem('password', password);
                sessionStorage.setItem('access_token', res.access_token);
                sessionStorage.setItem('id', res.id);

                if(remember){
                    localStorage.setItem('username', username);
                    localStorage.setItem('password', password);
                }
                this.setState({ username, isLogged: true });
                notify("Welcome!", "Login successfull", "success", "top-center");
            }
            else
            {
                notify("Error", JSON.stringify(res.message), "warning", "top-center");
            }
        })
        .catch( error => notify("Error", JSON.stringify(error), "warning", "top-center") )
    }

    logout = () => {
        console.log("Logout")
        localStorage.removeItem('username');
        localStorage.removeItem('password');
        sessionStorage.removeItem('id');
        sessionStorage.removeItem('username');
        sessionStorage.removeItem('password');
        sessionStorage.removeItem('access_token');
        this.setState({ username: '', isLogged: false });
    }

    componentDidMount() {
        let username = localStorage.getItem('username') || sessionStorage.getItem('username'),
            password = localStorage.getItem('password') || sessionStorage.getItem('password');
        if( username && password )
            this.login( username, password );
    }

    state = {
        isLogged: false,
        isAdmin: true,
        isFcrlab: true,
        username: '',
        login: this.login,
        logout: this.logout
    }

    render() {
        return (
            <Context.Provider value={this.state}>
                {this.props.children}
            </Context.Provider>
        );
    }
}

export const ContextConsumer = Context.Consumer;
