import React, { Component } from 'react'
import config from '@utils/config'
import Parser from 'html-react-parser'

class Node extends Component {
    state = {

    }

    render() {
        let node = this.props.node
        return(
            <div style={{padding: "10px 5% 10px 5%"}}>
                <h1>{ node.title }</h1>
                {node.image ? <img src={config.serverUrl+node.image} alt='NewsImage' width="100%" /> : null}
                <div style={bodyStyle}>
                    {Parser(node.body)}
                </div>
               
            </div>
        )
    }
}

export default Node

const bodyStyle = {
    textAlign: "justify",
    lineHeight: "25pt"
}