import React from 'react'

function Dropdown(props) {
    return (
        <div className="dropdown">
            <button className="dropbtn">{props.title}{' '}
                <i className="fa fa-caret-down"></i>
            </button>
            <div className="dropdown-content">
                {props.items}
            </div>
        </div> 
    )
}

export default Dropdown