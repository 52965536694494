import React from 'react'
import img from './services.png'

function Home(){
    return (
        <div style={{width: '100%', display:'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
            <h1>FCRLab Services</h1>
            <img src={img} alt="img" width="40%" />
        </div>
    )
}

export default Home