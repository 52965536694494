import React from 'react'
/* import { Link } from 'react-router-dom'; */
import config from '@utils/config'

function Item(props) {
    return (
/*         <Link to={props.link} > */
            <img src={config.serverUrl+props.imgUrl} style={{width: '300px', height: '120px'}} alt={props.title} className='opacity' />
/*         </Link> */
    )
}

export default Item