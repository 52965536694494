import React, {Component} from 'react'
import config from '@utils/config'
import SaveButton from '@common/SaveButton'
import {notify} from '@utils/notifications'

class UserSettings extends Component {
    state = {
        email: "",
        oldPassword: "",
        password: "",
        verifyPassword: "",
    }

    handleChange = (event) => {
        if(this.state.status !== 'unsaved')
            this.setState( {status: 'unsaved'} );
        const {name, value, type, checked} = event.target
        type === "checkbox" ? this.setState({ [name]: checked }) : this.setState({ [name]: value })
    }

    handleSubmit = (event, fun) => {
        let data = new FormData(),
            id = sessionStorage.getItem('id'),
            email = this.state.email,
            oldEmail = sessionStorage.getItem('username'),
            password = this.state.password,
            verifyPassword = this.state.verifyPassword,
            oldPassword = this.state.oldPassword;
        
        event.preventDefault();
        if(password){
            if(password === verifyPassword){
                data.append( 'password', password )
            }
            else
                return notify("Error!", "Password and VerifyPasswords does not match", "warning", "top-center");
        }
        if(email !== oldEmail)
            data.append( 'email', email )

        data.append('oldEmail', oldEmail);
        data.append('oldPassword', oldPassword);
        
        this.setState( {status: 'loading'} ); 
        fetch(config.serverUrl + '/users/updateUser/'+id, {
            method: 'PATCH',
            headers: {'access-token': sessionStorage.getItem('access_token')},
            body: data
        })
        .then( response => response.json() )
        .then( (res) => {
            if(res.status === 200)
            {
                notify("Success!", res.body, "success", "top-center");
                this.setState( {status: 'saved'} );
                sessionStorage.setItem('username', email);
                sessionStorage.setItem('password', password);
            }
            else{
                this.setState( {status: 'error'} );
                notify("Error!", res.body, "warning", "top-center");
            }
        })
        .catch( error => { 
            this.setState( {status: 'error'} );
            notify("Error", JSON.stringify(error), "warning", "top-center")
        })
    }

    componentDidMount() {
        this.setState({
            email: sessionStorage.getItem('username') || "",
            loading: false,
            newFile: false
        })
    }

    render() {
        return (
            <div style={{background: 'white', height: '100%', marginLeft: '5px'}}>
                <form style={formStyle} onSubmit={this.handleSubmit}>
                    <h1>Change your user credentials</h1>
                    <h4>Email address</h4>
                    <input 
                        type="text" 
                        name="email" 
                        placeholder="Your email address" 
                        onChange={this.handleChange} 
                        value={this.state.email}
                        required 
                    /> 
                    <h4>Change your password</h4>
                    <input 
                        type="password" 
                        name="password" 
                        placeholder="Your new password" 
                        onChange={this.handleChange} 
                        value={this.state.password}
                    />  
                    <input 
                        type="password" 
                        name="verifyPassword" 
                        placeholder="Verify new password" 
                        onChange={this.handleChange} 
                        value={this.state.verifyPassword}
                    />  

                    <p><i>You have to insert your old password in order to edit your user account credentials.</i></p>
                    <input 
                        type="password" 
                        name="oldPassword" 
                        placeholder="Your old password" 
                        onChange={this.handleChange} 
                        value={this.state.oldPassword}
                        required 
                    />  
                    <SaveButton status={this.state.status} />
                </form>
            </div>
        )
    }
}

const formStyle= {
    display: 'flex', 
    flexDirection: 'column',
    alignItems: 'left', 
    width: '100%%',
    minWidth: '280px',
    maxWidth: '700px',
    padding: '40px 5% 40px 5%',
}

export default UserSettings
