import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import config from '@utils/config'
import Loading from '@common/Loading'
import SaveButton from '@common/SaveButton'
import { notify } from '@utils/notifications'
import DatePicker from 'react-datepicker';

import Form from '@common/Form'
import Column from '@common/Form/Column'
import FormSection from '@common/Form/FormSection'
import AskToSign from '@common/AskToSign'

import 'react-datepicker/dist/react-datepicker.css';

class TimeReports extends Component {
    state = {
        projectsList: [],
        selected: 0,
        name: "", surname: "", img: "", withCost: false, link1: "", link2: "",
        askToSign: false
    }

    updateImage = (newImage) => {
        let newFile = newImage.newfile,
            blob = newImage.blob;

        if (this.state.status !== 'unsaved')
            this.setState({ status: 'unsaved' });
        this.setState({ newFile, blob })
    }

    handleChange = (event) => {
        if (this.state.status !== 'unsaved')
            this.setState({ status: 'unsaved' });
        const { name, value, type, checked } = event.target
        type === "checkbox" ? this.setState({ [name]: checked }) : this.setState({ [name]: value })
    }

    changeDate = (key, startDate) => {
        this.setState({ [key]: startDate })
    }

    handleSubmit = (event, fun) => {
        let data = {},
            i = this.state.selected,
            askToSign = this.state.askToSign,
            project = this.state.projectsList[i];

        //data.title = this.state.title;
        data.date_start = this.state.startDate.toLocaleDateString("en-CA", { day: "2-digit", month: "2-digit", year: "numeric" });
        data.date_end = this.state.endDate.toLocaleDateString("en-CA", { day: "2-digit", month: "2-digit", year: "numeric" });
        data.date_sign = this.state.signDate.toLocaleDateString("en-CA", { day: "2-digit", month: "2-digit", year: "numeric" });
        data.project_id = project.openprojectId;
        data.project_name = project.info.projectName;
        data.project_code = project.info.projectCode;
        data.actuator = project.info.actuator;
        data.local_location = project.info.permanentLocation[0];
        data.foreign_location = project.info.remoteLocation[0];
        data.activity_description = project.info.activityDescription;
        data.minimum_time = project.info.estimatedWorkingHours;
        data.contract = project.info.appliedContract;
        data.name = this.state.name;
        data.surname = this.state.surname;
        data.with_cost = String(this.state.withCost);
        data.img_url = config.serverUrl + project.headerImg;

        let timelog = project.documents.timelog.requiredSignatures.map(sign => {
            if (!sign.userId)
                sign.userId = sessionStorage.getItem('id')
            return sign
        });
        let timesheet = project.documents.timesheet.requiredSignatures.map(sign => {
            if (!sign.userId)
                sign.userId = sessionStorage.getItem('id')
            return sign
        });
        this.setState({ status: 'loading' });

        this.setState({ status: 'loading' });
        event.preventDefault();

        fetch(config.serverUrl + '/api/services/build/timereports', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'access-token': sessionStorage.getItem('access_token')
            },
            body: JSON.stringify({ data, askToSign, requiredSignatures: { timelog, timesheet } })
        })
            .then(response => response.json())
            .then((res) => {
                console.log(res)
                if (res.status === 200) {
                    notify("Success!", "Created", "success", "top-center");
                    this.setState({ status: 'saved', 
                    link1: config.serverUrl + "/api/services/document?path=/" + res.body.ts_name,
                    link2: config.serverUrl + "/api/services/document?path=/" + res.body.tl_name 
                });
                }
                else {
                    this.setState({ status: undefined });
                    notify("Warning!", "No file generated", "warning", "top-center");
                }
            })
            .catch(error => {
                this.setState({ status: 'error' });
                notify("Error", JSON.stringify(error), "warning", "top-center")
            })
    }

    componentDidMount() {
        let url = config.serverUrl + "/users/getUserInfo/" + sessionStorage.getItem('id') + "?fields=email,name,surname",
            name, surname;
        this.setState({ loading: true });
        fetch(url)
            .then(response => response.json())
            .then((data) => {
                let url2 = config.serverUrl + "/api/services/getProjects?user=" + sessionStorage.getItem('id');
                name = data.name;
                surname = data.surname;
                return fetch(url2, { headers: { 'access-token': sessionStorage.getItem('access_token') } })
            })
            .then(response => response.json())
            .then(data => {
                if (data) {
                    let date = new Date(),
                        date_start = new Date(date.setMonth(date.getMonth() - 1)),
                        date_sign = new Date(date.setMonth(date.getMonth() + 1))
                    this.setState({
                        projectsList: data,
                        selected: 0,
                        name: name,
                        surname: surname,
                        startDate: date_start,
                        endDate: date_sign,
                        signDate: date_sign
                    })
                }
                this.setState({ loading: false });
            })
    }

    render() {
        let projects = this.state.projectsList,
            i = this.state.selected,
            link1 = this.state.link1,
            link2 = this.state.link2,
            loading = this.state.loading;

        if (loading)
            return <Loading />;

        if (projects.length === 0) {
            return (
                <div style={{ background: 'white', height: '100%', marginLeft: '5px' }}>
                    <form style={formStyle} onSubmit={this.handleSubmit}>
                        <h1>You don't belong to any project</h1>
                    </form>
                </div>
            )
        }
        let check = projects[this.state.selected].documents.timesheet && projects[this.state.selected].documents.timelog,
            sign = check ?
                projects[this.state.selected].documents.timelog.requiredSignatures.length > 0 ?
                    <FormSection title="Signature">
                        <AskToSign
                            requiredSignatures={projects[this.state.selected].documents.timelog.requiredSignatures}
                            handleChange={this.handleChange}
                            askToSign={this.state.askToSign}
                        /> 
                    </FormSection> :
                    null
                :
                <h4>This project has no TimeReports</h4>

        let projectsItem = projects.map((item, i) => <option key={i} value={i}>{item.openprojectId}</option>);
        let permanentLocations = projects[i].info.permanentLocation.map((item, i) => <option key={i} value={item}>{item}</option>);
        let remoteLocations = projects[i].info.remoteLocation.map((item, i) => <option key={i} value={item}>{item}</option>)

        return (
            <Form title="OpenProject TimeReport Generator" handleSubmit={this.handleSubmit}>
                <Column>
                    <FormSection title="Personal Info">
                        <label>Name and Surname*</label>
                        <div style={{ display: 'flex', width: '100%'}}>
                            <input
                                type="text"
                                name="name"
                                placeholder="Your name"
                                onChange={this.handleChange}
                                value={this.state.name}
                                required
                            />
                            <input
                                type="text"
                                name="surname"
                                placeholder="Your surname"
                                onChange={this.handleChange}
                                value={this.state.surname}
                                required
                            />
                        </div>
                        <br />
                        <div style={{ display: 'flex', width: '100%', flexWrap:'wrap', justifyContent: 'space-between' }}>
                            <div>
                                <label style={{ marginBottom: "5px" }}>Start Date*</label><br />
                                <DatePicker
                                    placeholderText="Click to select a date"
                                    selected={this.state.startDate}
                                    onChange={date => this.changeDate("startDate", date)}
                                    customInput={<input type="text" />}
                                    dateFormat="dd/MM/yyyy"
                                />
                            </div>
                            <div>
                                <label style={{ marginBottom: "5px" }}>End Date*</label><br />
                                <DatePicker
                                    placeholderText="Click to select a date"
                                    selected={this.state.endDate}
                                    onChange={date => this.changeDate("endDate", date)}
                                    customInput={<input type="text" />}
                                    dateFormat="dd/MM/yyyy"
                                />
                            </div>
                            <div><label style={{ marginBottom: "8px" }}>Signature Date*</label><br />
                                <DatePicker
                                    name="signDate"
                                    placeholderText="Click to select a date"
                                    selected={this.state.signDate}
                                    onChange={date => this.changeDate("signDate", date)}
                                    customInput={<input type="text" />}
                                    dateFormat="dd/MM/yyyy"
                                />
                            </div>
                        </div>
                        <br />
                        <div style={{ display: "flex", alignItems: "center" }}><input
                            name="withCost"
                            type="checkbox"
                            checked={this.state.withCost}
                            onChange={this.handleChange}
                            style={{ width: "25px", height: "25px" }}
                        /> I want to calculate cost report</div>
                    </FormSection>
                    {sign}
                </Column>
                <Column>
                    <FormSection title="Project Info">
                        <label>Project*</label>
                        <select name="selected" value={this.state.selected} onChange={this.handleChange}>
                            {projectsItem}
                        </select>
                        <input
                            type="text"
                            name="projectCode"
                            placeholder="Project Code"
                            value={projects[i].info.projectCode}
                            disabled
                        />
                        <input
                            type="text"
                            name="actuator"
                            placeholder="Actuator Subject"
                            value={projects[i].info.actuator}
                            disabled
                        />
                        <input
                            type="text"
                            name="estimatedWorkingHours"
                            placeholder="Estimated Working Hours"
                            value={"Estimated Working Hours: " + projects[i].info.estimatedWorkingHours}
                            disabled
                        />
                        <input
                            type="text"
                            name="appliedContract"
                            placeholder="Applied Contract"
                            value={projects[i].info.appliedContract}
                            disabled
                        /><br /><br />
                        <label style={{ maxWidth: '385px' }}>Permanent Location</label>
                        <select name="permanentLocation" onChange={this.handleChange} disabled>
                            {permanentLocations}
                        </select>
                        <label style={{ maxWidth: '385px' }}>Remote Location</label>
                        <select name="remoteLocation" onChange={this.handleChange} disabled>
                            {remoteLocations}
                        </select>
                    </FormSection>
                </Column>
                <br />
                {check ? <SaveButton status={this.state.status} /> : null}

                {link1 ?
                    // eslint-disable-next-line
                    <a target='_blank' rel="noopener noreferrer" href={link1}>LINK TIMESHEET</a>
                    : null}
                {link2 ?
                    // eslint-disable-next-line
                    <a target='_blank' rel="noopener noreferrer" href={link2}>LINK TIMELOG</a>
                    : null}
            </Form>
        )
    }
}

const formStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    width: '100%%',
    minWidth: '280px',
    maxWidth: '1000px',
    padding: '40px 5% 40px 5%',
}

export default withRouter(TimeReports)