import React, { Component } from 'react'
import { GoogleMap, Marker, DirectionsService, DirectionsRenderer, LoadScript } from '@react-google-maps/api'
import BusMarker from './BusMarker'
//import { busMarker } from './bus.png'

var debounce = true

class Map extends Component {
	constructor() {
        super();
        this.busMarker = React.createRef();
	}
	
	directionsCallback(response) {
		if (response !== null && debounce) {
			if (response.status === 'OK') {
				debounce = false;
				this.props.setPath(response);
				setTimeout(() => debounce = true, 500)
			} else {
				console.log('response: ', response)
			}
		}
	}

	updateBus(bus) {
		setTimeout(() => {
		if (this.busMarker.current)
			this.busMarker.current.updateBus(bus)
		}, 2000);
	}

	render() {
		let steps,
			markers,
			line = this.props.line,
			path = this.props.path;

		if (line) {
			steps = line.steps.map((item) => ({ location: { lng: item.location.coordinates[0], lat: item.location.coordinates[1] } }));

			markers = steps.map((item, index) => <Marker
				key={index}
				onClick={((e) => this.props.selectStep(index))}
				position={{
					lat: item.location.lat,
					lng: item.location.lng
				}}
			/>)

			if (line.circular)
				steps.push(steps[0])
		}

		return (
			<div>
				<LoadScript
					id="script-loader"
					googleMapsApiKey="AIzaSyDYIF0yNJ21-0jIHgjc0gsFANMSWAw7wpg"
				>
					<GoogleMap
						id="map"
						onLoad={() => {return 0;}}
						mapContainerStyle={{
							height: "400px",
							width: "100%"
						}}
						zoom={13}
						center={{
							lat: 38.253631,
							lng: 15.595360
						}}
						zoomOnClick={false} 
					>

						{
							(steps) ?
								<div>			
									{markers}
									<BusMarker ref={this.busMarker} />
									{path ? null : <DirectionsService
										options={{
											destination: steps[steps.length - 1].location,
											origin: steps[0].location,
											waypoints: steps.slice(1, steps.length - 1),
											travelMode: "DRIVING"
										}}
										callback={(response) => this.directionsCallback(response)
										}
									/>}
									{path && path.status === "OK" ?
										<DirectionsRenderer
											options={{
												directions: path,
												suppressMarkers: true,
												//preserveViewport: true
											}}
										/>
										:
										null
									}
								</div>
								: <div>Null</div>

						}
					</GoogleMap>
				</LoadScript >
			</div>
		)
	}
}

export default Map