import React from 'react'
import { Link } from 'react-router-dom'

function NodeInfo(props) {
    let node = props.node
    let tags = node.tags ? node.tags.map((tag, index) => <Link to={"/tags/"+tag} key={index} style={tagStyle}>{ tag }</Link> ) : null,
        {category, link} = node

    if (category === "call")
        var {shortTitle, place, date } = node;
    return (
        <div style={{marginTop: "0px", padding:"10px 15px", textAlign: "left"}}>
            { category === "call" ? <div>
                <table>
                  <tr> 
                    <td style={{width: "20%"}}><i
                       style={{ color: '#222222'}} 
                       className="fa fa-2x fa-angle-double-right  " 
                       title={'Call title'} 
                   /></td>
                   <td style={{textAlign: "left"}}>{ shortTitle } </td>
                 </tr>
                 { date ? <tr> 
                    <td style={{width: "20%"}}><i
                       style={{ fontSize: "1.5em", color: '#222222'}} 
                       className="fa fa-calendar  " 
                       title={'Date'} 
                   /></td>
                   <td style={{textAlign: "left"}}>{ date } </td>
                 </tr> : null }
                 { place ? <tr> 
                    <td style={{width: "20%"}}><i
                       style={{ color: '#222222'}} 
                       className="fa fa-2x fa-map-marker  " 
                       title={'Place'} 
                   /></td>
                   <td style={{textAlign: "left"}}>{ place } </td>
                 </tr> : null }
                </table>
            </div> : null }
            <br/>
            <div>
            { link ? <p><strong>Link</strong>: <Link to={link} >{ link } </Link></p> : null}
            {/*<p><strong>Author</strong>: <Link style={{color: "black", textDecoration: "none"}} to={"/user/"+node.author.id } >{ node.author.name + " " + node.author.surname }</Link> </p>*/}
            {/*{node.modifier ? <p><strong>Modified by</strong>: <Link style={{color: "black", textDecoration: "none"}} to={"/user/"+node.modifier.id } >{ node.modifier.name + " " + node.modifier.surname }</Link> </p> : null} */}
            <p><strong>Date created</strong>: { node.dateCreated.replace(/T/, ' ').replace(/\..+/, '').slice(0, -3) } </p>
            {node.dateModified ? <p><strong>Date modified</strong>: { node.dateModified.replace(/T/, ' ').replace(/\..+/, '').slice(0, -3) } </p> : null}
            <p><strong>Category</strong>: <Link style={{color: "black", textDecoration: "none"}} to={"/category/"+node.category } >{ node.category } </Link></p>
            <br />
         
            {tags ? <div>
                <strong>Tags</strong> <hr />
                <div style={{display: "flex", flexWrap: "wrap"}}> { tags } </div>
                <br />
            </div> : null}

            {node.description ? <div>
                <p><strong>Description</strong>: </p>
                <hr />
                <p style={{textAlign: "justify"}}> { node.description } </p>
            </div> : null}
            </div>
        </div>
    )
}

const tagStyle = {
    display: 'inline-block',
    cursor: 'pointer',
    padding: "7px",
    background: "#cccccc",
    marginRight: "5px",
    marginTop: "5px",
    fontSize: "11pt",
    textDecoration: 'none',
    color: 'black'
}

export default NodeInfo
