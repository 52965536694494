import React from 'react'
import Item from './Item'
import colors from '@utils/colors'

function ContactUs() {
    return (
        <div style={contactStyle}>
            <Item 
                icon={'fa fa-home'} 
                title={'ADDRESS'} 
                text={'Dipartimento di Ingegneria 98166, Messina (ME) {"lat":38.259413,"lon":15.596191}'} 
            />
            <Item 
                icon={'fa fa-phone'} 
                title={'PHONE NUMBERS'} 
                text={'Phone: (0039) 0903977342'} 
            />
            <Item 
                icon={'fa fa-envelope'} 
                title={'EMAIL'} 
                text={'fcrlabunime@gmail.com'} 
            />
        </div>
    )
}

const contactStyle = {
    padding: '10px 0',
    display: 'flex', 
    flexWrap: 'wrap',
    justifyContent: 'space-evenly',
    width: '100%',
    backgroundColor: colors.color2,
    color: colors.color5
}

export default ContactUs
