import React, {Component} from 'react'
import config from '@utils/config'
import { Link } from 'react-router-dom'
import '../style.css'

class Slide extends Component {
    render() {
        let imgUrl = config.serverUrl + this.props.item.image
        let title = this.props.item.title
        let link = this.props.item.category === "call" ? `/calls/${this.props.item.slug}` : `/news/${this.props.item.slug}`;
        let description = this.props.item.description
        return(
            <div className="caption" style={{maxWidth:"1000px", maxHeight:"500px"}}>
                <Link to={link} >
                    <img src={imgUrl} alt={title} />
                    <span>
                        <strong style={{fontSize: '1em'}}>{title}</strong>
                        <div className="hideOnMobile">{description}</div>
                    </span>
                </Link>
            </div>
        )
    }
}

export default Slide
