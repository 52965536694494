import React from 'react'

function UserPage(props) {
    // eslint-disable-next-line
    let user = props.user
    return (
        <div>
            <br />
            Pagina in costruzione
            <br/> <br />
        </div>
    )
}

export default UserPage 