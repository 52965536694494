import React from 'react'

function Form(props) {
    return (
        <div style={{ background: 'white', height: '100%', marginLeft: '5px' }}>
            <form style={formStyle} onSubmit={props.handleSubmit}>
                <center><h1>{props.title}</h1></center>
                <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%', justifyContent: 'space-evenly' }}>
                    {props.children}
                </div>
            </form>
        </div>
    )
}

const formStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    width: '100%%',
    minWidth: '280px',
    padding: '40px 5% 40px 5%',
}

export default Form;