import React, { Component } from 'react'
import Item from './Item'

class Menu extends Component {
    render() {
        let settingsUser = this.props.userSettings.map(item => <Item
            key={item.id}
            id={item.id}
            title={item.title}
            icon={item.icon}
            selected={this.props.selected === item.id}
            handleClick={this.props.handleClick}
        />)
        let servicesInfo = this.props.servicesInfo.map(item => <Item
            key={item.id}
            id={item.id}
            title={item.title}
            icon={item.icon}
            selected={this.props.selected === item.id}
            handleClick={this.props.handleClick}
        />)
        let settingsAdmin = this.props.adminSettings.map(item => <Item
            key={item.id}
            id={item.id}
            title={item.title}
            icon={item.icon}
            selected={this.props.selected === item.id}
            handleClick={this.props.handleClick}
        />)
        return (
            <div style={menuStyle}>
                <center><h3>User settings</h3></center>
                {settingsUser}
                <center><h3>Services Info</h3></center>
                {servicesInfo}
                <center><h3>Administration</h3></center>
                {settingsAdmin}
                <div style={{ height: '50px' }}></div>
            </div>
        )
    }
}

const menuStyle = {
    flex: '1',
    minWidth: '180px',
    background: 'white'
}

export default Menu