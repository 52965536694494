import React, {Component} from 'react'
import { Link } from 'react-router-dom'
import { Context } from '@utils/Context'
import colors from '@utils/colors'

class Form extends Component {
    static contextType = Context;

    state = {
        username:"",
        password:"",
        remember: false
    }

    handleChange = (event) => {
        const {name, value, type, checked} = event.target
        type === "checkbox" ? this.setState({ [name]: checked }) : this.setState({ [name]: value })
    }

    handleSubmit = (event, fun) => {
        event.preventDefault();
        this.context.login(this.state.username, this.state.password, this.state.remember);
    }

    render() {
        return (
            <div style={{padding: '20px'}}>
                <form style={formStyle} onSubmit={this.handleSubmit}>
                    <h1>Log in</h1>
                    <input 
                        type="text" 
                        name="username" 
                        placeholder="Your email" 
                        onChange={this.handleChange} 
                        value={this.state.username}
                        required 
                    />
                    <input 
                        type="password" 
                        name="password" 
                        placeholder="Your password" 
                        onChange={this.handleChange} 
                        value={this.state.password}
                        required 
                    />  
                    <div style={{marginTop: "10px"}}><input type="checkbox" name="remember" checked={this.state.remember} onChange={this.handleChange} />Remember me</div>
                    <button style={btnStyle}>Log In</button>
                    <div style={{display: 'flex', alignItems:"center", justifyContent: 'space-between', width:"100%"}}>
                        <Link to="/signup">Sign up</Link>
                        <p />
                        {/*<Link to="/recoverypassword">Password forgotten?</Link>*/}
                    </div>
                </form>
            </div>
        )

    }
}

Form.contextType = Context;

const formStyle= {
    display: 'flex', 
    flexDirection: 'column',
    alignItems: 'left', 
    justifyContent: 'center',
    width: '20%',
    minWidth: '280px',
    background: colors.color5,
    borderRadius: "5px",
    padding: '40px 5% 40px 5%',
    margin: '20px auto 20px auto'
}

const btnStyle = {
    width: "100%",
    height: "40px",
    margin: "20px 0px",
    background: colors.color2,
    border: "none",
    borderRadius: "5px",
    color: colors.color5,
    fontWeight: "bold",
    cursor: "pointer"
}

export default Form
