import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import logo0 from './fcrlab-logo-transparent.svg'
import logo1 from './iron-man-logo.png'

class Logo extends Component {
    state = {
        logo: [logo0, logo1],
        index: 0,
        clicks: 0
    }

    tick = () => {

        // eslint-disable-next-line
        this.state.clicks++
        if(this.state.clicks === 7)
        {
            let newIndex = (this.state.index+1)%2
            this.setState({index: newIndex})
        } 
        setTimeout(() => {
            if(this.state.clicks > 0){
                this.setState({clicks: 0})
            }
        }, 1500 );
    }

    render(){
        return(
            <div style={{width: "70px", textAlign: 'center'}}>
                <Link to="/">
                    <img src={ this.state.logo[this.state.index] } height="40px" alt="logo" onClick={ this.tick }/>
                </Link>
            </div>
        )
    }
}

export default Logo
