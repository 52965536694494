import React, { Component } from 'react'
import Form from '@common/Form'
import colors from '@utils/colors'
import { notify } from '@utils/notifications'
import config from '@utils/config'

import Page1 from './modals/Page1'
import Page2 from './modals/Page2'
import Page3 from './modals/Page3'
import Modal from 'react-responsive-modal';
import Item from './Item'

class Signatures extends Component {
    state = {
        signatures: [],
        open1: false,
        open2: false,
        open3: false,
        open4: false,
        blob: null,
        lineHeight: 20,
        passphrase: '',
        loading: false,
        selected: null
    }

    updateImage = (newImage) => {
        let newFile = newImage.newfile,
            blob = newImage.blob,
            imageUrl = newImage.image;
        this.setState({ newFile, blob, imageUrl })
    }

    sliderChange = (x) => {
        this.setState({ lineHeight: x })
    }

    handleDrop = dropped => {
        this.setState({ image: dropped[0] })
    }

    onOpenModal = (e) => {
        e.preventDefault();
        this.setState({ open1: true });
    };

    onCloseModal = () => {
        this.setState({ open1: false, open2: false, open3: false, open4: false });
    };

    previous = (page) => {
        if (page === 0)
            this.setState({ open3: false, open2: false, open1: true });
        else
            this.setState({ open3: false, open2: true, open1: false });
    };

    next = () => {
        this.setState({ open3: true, open2: false, open1: false, passphrase: '' });
    }

    imageLoaded = (e) => {
        console.log("IMAGE URL", typeof this.state.imageUrl)
        e.preventDefault();
        if(typeof this.state.imageUrl === "string")
            this.setState({ open1: false, open2: true })
        else
            notify("Error", "You have to crop your image signature", "warning", "top-center")
    }

    setPassphrase = (passphrase) => {
        this.setState({ passphrase })
    }
    preview = (e, index) => {
        e.preventDefault();
        this.setState({ selected: index, open4: true })
    }
    testSign = (e, version) => {
        let id = sessionStorage.getItem('id'),
            { signatures, passphrase } = this.state,
            data = new FormData()

        data.append('lineHeight', signatures[version].lineHeight)
        data.append('passphrase', passphrase)
        e.preventDefault();
        fetch(config.serverUrl + '/api/services/testSign/' + id + '/' + version, {
            method: 'POST',
            headers: { 'access-token': sessionStorage.getItem('access_token') },
            body: data
        })
            .then(response => response.json())
            .then((res) => {
                console.log(JSON.stringify(res))
                if (res.status === 200) {
                    notify("Success!", res.body, "success", "top-center");
                    let link = config.serverUrl+"/api/services/document?path="+res.body.split('/home/rsync')[1]
                    window.open(link, "_blank")
                }
                else {
                    notify("Error!", res.body, "warning", "top-center");
                }
                this.setState({ open4: false, loading: false, passphrase: '' });
            })
            .catch(error => {
                notify("Error", JSON.stringify(error), "warning", "top-center")
                this.setState({ open4: false, loading: false, passphrase: '' });
            })
    }

    submit = (e) => {
        let data = new FormData(),
            { blob, lineHeight, passphrase, newFile, signatures } = this.state,
            versionList = signatures.map(item => item.version),
            version = 0,
            id = sessionStorage.getItem('id')

        this.setState({ loading: true })
        while (true) {
            if (version in versionList)
                version++;
            else
                break;
        }

        e.preventDefault();
        if (newFile) {
            var reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
                var base64data = reader.result;
                data.append('image', base64data)
                data.append('lineHeight', lineHeight)
                data.append('passphrase', passphrase)
                fetch(config.serverUrl + '/api/services/signatures/' + id + '/' + version, {
                    method: 'POST',
                    headers: { 'access-token': sessionStorage.getItem('access_token') },
                    body: data
                })
                    .then(response => response.json())
                    .then((res) => {
                        if (res.status === 200) {
                            notify("Success!", res.body, "success", "top-center");
                        }
                        else {
                            notify("Error!", res.body, "warning", "top-center");
                        }
                        this.setState({ open3: false, loading: false, passphrase: '' });
                        this.componentDidMount()
                    })
                    .catch(error => {
                        notify("Error", JSON.stringify(error), "warning", "top-center")
                        this.setState({ open3: false, loading: false, passphrase: '' });
                    })
            }
        }
    }

    componentDidMount() {
        let url = config.serverUrl + "/api/services/signatures/" + sessionStorage.getItem('id');
        fetch(url, { headers: { 'access-token': sessionStorage.getItem('access_token') } })
            .then(response => response.json())
            .then(data => {
                if (data)
                    this.setState({ signatures: data })
            })
    }

    render() {
        let { open1, open2, open3, open4, blob, imageUrl, lineHeight, passphrase, signatures, loading, selected } = this.state,
            signs = signatures.map((item, i) => <Item key={i} id={i} item={item} preview={this.preview} />)
        return (
            <div>
                <Form title="Signatures">
                    <div style={{ width: '100%', height: '100%' }}>
                        {signs.length > 0 ? null : <button onClick={this.onOpenModal} style={btnStyle}>Add new signature</button> }
                        <Modal open={open1} onClose={this.onCloseModal} center>
                            <Page1 blob={blob} updateImage={this.updateImage} />
                            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                <button style={btnStyle} onClick={this.imageLoaded}>Next</button>
                            </div>
                        </Modal>
                        <Modal open={open2} onClose={this.onCloseModal} center>
                            <Page2 imageUrl={imageUrl} sliderChange={this.sliderChange} lineHeight={lineHeight} />
                            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly' }}>
                                <button style={btnStyle} onClick={() => this.previous(0)}>Previous</button>
                                <button style={btnStyle} onClick={this.next}>Next</button>
                            </div>
                        </Modal>
                        <Modal open={open3} onClose={this.onCloseModal} center>
                            <Page3 passphrase={passphrase} setPassphrase={this.setPassphrase} loading={loading} />
                            {loading ? null :
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly' }}>
                                    <button style={btnStyle} onClick={() => this.previous(1)}>Previous</button>
                                    <button style={btnStyle} onClick={this.submit}>Create</button>
                                </div>
                            }
                        </Modal>
                        <Modal open={open4} onClose={this.onCloseModal} center>
                            <Page3 passphrase={passphrase} setPassphrase={this.setPassphrase} loading={loading} />
                            {loading ? null :
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly' }}>
                                    <button style={btnStyle} onClick={this.onCloseModal}>Back</button>
                                    <button style={btnStyle} onClick={(e) => this.testSign(e, selected)}>Test</button>
                                </div>
                            }
                        </Modal>
                    </div>
                    <div style={{ width: '100%' }}>
                        {signs}
                    </div>
                </Form>
            </div >
        )
    }
}

const btnStyle = {
    width: "50%",
    maxWidth: '150px',
    height: "40px",
    margin: "20px 0px",
    background: colors.color2,
    border: "none",
    borderRadius: "5px",
    color: colors.color5,
    fontWeight: "bold",
    cursor: "pointer",
    marginRight: '20px'
}

export default Signatures