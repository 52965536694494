import React, { Component } from 'react'

class SubscribeForm extends Component {

    render() {
        let line = this.props.line,
            content;
        if(line) {
            content = line.steps.map((item, index) =>
                <div key={index} style={divStyle} onClick={() => this.props.selectStep(index)}>
                    {item.name}
                </div>
            )
        }
        return (
            <div style={{ display: 'flex', flexDirection: 'column', height: '400px', overflowY: 'overflow' }}>
                {content}
            </div>
        )
    }
}

const divStyle = {
    flex: '1',
    minHeight: '50px',
    borderBottom: '1px solid #cccccc',
    display: 'flex',
    alignItems: 'center',
    padding: '0 20px',
    cursor: 'pointer',
    textAlign: 'left'
}

export default SubscribeForm