import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import colors from '@utils/colors'
import config from '@utils/config'
import Loading from '@common/Loading'
import Modal from 'react-responsive-modal';
import { notify } from '@utils/notifications'

class Item extends Component {
    state = {
        selectedSign: 0,
        signatures: [],
        open: false,
        loading: true,
        passphrase: '',
        removed: false
    }

    viewDocument = (uri) => {
        let link = config.serverUrl + "/api/services/document?path=" + uri
        window.open(link, "_blank")
    }

    signDocument = () => {
        this.setState({ open: true, passphrase: '' })
        let url = config.serverUrl + "/api/services/signatures/" + sessionStorage.getItem('id');
        fetch(url, { headers: { 'access-token': sessionStorage.getItem('access_token') } })
            .then(response => response.json())
            .then(data => {
                if (data)
                    this.setState({ signatures: data, loading: false })
            })
    }

    removeDocument = () => {
        let { _id } = this.props.item,
            url = config.serverUrl + '/api/services/documentToSign/' + _id;

        let ok = window.confirm("Are you sure you want to delete the document?");
        if(!ok)
            return;
        fetch(url, { method: 'DELETE', headers: { 'access-token': sessionStorage.getItem('access_token') } })
            .then(response => response.json())
            .then((res) => {
                if (res.status === 200) {
                    this.props.reflesh();
                }
                else {
                    notify("Error!", res.body, "warning", "top-center");
                }
                this.setState({ open: false, loading: false });
            })
            .catch(error => {
                notify("Error", JSON.stringify(error), "warning", "top-center")
                this.setState({ open: false, loading: false });
            })

    }

    sign = () => {
        let id = sessionStorage.getItem('id'),
            { passphrase, signatures, selectedSign } = this.state,
            { lineHeight, version } = signatures[selectedSign],
            { _id, unsignedDocument, signedDocument, requiredSignatures, nextSignature, orientation, searchSign, page } = this.props.item,
            inputPDF, outputPDF,
            url = config.serverUrl + "/api/services/sign/" + _id + "/" + id + "/" + version,
            data = new FormData()

        inputPDF = this.props.nextSignature === 0 ? unsignedDocument : signedDocument
        for (let i = 0; i < this.props.nextSignature; i++)
            inputPDF += "_" + i

        outputPDF = signedDocument
        for (let i = 0; i < this.props.nextSignature; i++)
            outputPDF += "_" + i

        data.append("inputPDF", inputPDF + "." + this.props.item.format)
        data.append("outputPDF", outputPDF + "_" + nextSignature + '.' + this.props.item.format)
        data.append("passphrase", passphrase)
        data.append("lineHeight", lineHeight)
        data.append("signs", JSON.stringify([requiredSignatures[nextSignature].position]))
        data.append("orientation", orientation)
        data.append("searchSign", searchSign)
        data.append("page", page)

        this.setState({ loading: true, passphrase: '' })
        fetch(url, {
            method: 'POST',
            headers: { 'access-token': sessionStorage.getItem('access_token') },
            body: data
        })
            .then(response => response.json())
            .then((res) => {
                if (res.status === 200) {
                    notify("Success!", "Document signed successfully", "success", "top-center");
                    this.props.reflesh();
                }
                else {
                    notify("Error!", res.body, "warning", "top-center");
                }
                this.setState({ open: false, loading: false });
            })
            .catch(error => {
                notify("Error", JSON.stringify(error), "warning", "top-center")
                this.setState({ open: false, loading: false });
            })
    }

    onCloseModal = () => {
        this.setState({ open: false, passphrase: "", selectedSign: 0 });
    };

    handleChange = (event) => {
        if (this.state.status !== 'unsaved')
            this.setState({ status: 'unsaved' });
        const { name, value, type, checked } = event.target
        type === "checkbox" ? this.setState({ [name]: checked }) : this.setState({ [name]: value })
    }

    render() {
        let item = this.props.item,
            { open, signatures, selectedSign, loading } = this.state,
            mySignatures = signatures.map((item, i) => <option value={i} key={i}>Signature {i}</option>),
            date = new Date(item.date).toLocaleString('it-IT', { dateStyle: 'short' }),
            viewDocument = this.props.nextSignature === 0 ? item.unsignedDocument : item.signedDocument,
            doc = item.unsignedDocument.split("/")
        for (let i = 0; i < this.props.nextSignature; i++)
            viewDocument += "_" + i
        viewDocument += "." + item.format
        doc = doc[doc.length - 1]
        return (
            <div style={divStyle}>
                <div style={{flex: 3}}>
                    <div><strong>Applicant:</strong> <Link to={"user/" + item.applicant.userId}>{item.applicant.name + " " + item.applicant.surname}</Link></div>
                    {item.requiredSignatures[item.nextSignature] ? 
                        <div><strong>Next signature:</strong> <Link to={"user/"+item.requiredSignatures[item.nextSignature].userId}>{item.requiredSignatures[item.nextSignature].role}</Link></div> : 
                        null}
                    <div><strong>Document:</strong> {doc}</div>
                </div>
                <div style={{flex: 1}}>
                    <div><strong>Type:</strong> {item.type}</div>
                    <div><strong>Signatures:</strong> {item.nextSignature + "/" + item.requiredSignatures.length}</div>
                    <div><strong>Status:</strong> {item.nextSignature === item.requiredSignatures.length ? 
                        <span style={{color: 'green'}}>Complete</span> : 
                        <span style={{color: 'orange'}}>Pending</span>}
                    </div>
                </div>
                <div style={{flex: 1}}><strong>Date:</strong> {date}</div>
                <div style={{flex: 1}}>
                    <button style={btnStyle} onClick={() => this.viewDocument(viewDocument)}>View document</button><br/>
                    {this.props.toSign ? <div><button style={btnStyle} onClick={this.signDocument}>Sign document</button><br/>
                               <button style={btnStyle} onClick={this.removeDocument}>Remove document</button></div> : null}
                </div>

                <Modal open={open} onClose={this.onCloseModal} center>
                    {loading ? <Loading /> :
                        mySignatures.length > 0 ?
                            <div>
                                <h2>Signing</h2>
                                Select a signature
                                <select name="selectedSign" value={selectedSign} onChange={this.handleChange} >
                                    {mySignatures}
                                </select>
                                Passphrase:
                                <input type='password' name="passphrase" onChange={this.handleChange} />
                                <button style={btnStyle} onClick={this.sign}>Sign document</button>
                            </div> :
                            <div>
                                <h2>Signing</h2>
                                <div>You have to define a signature in your profile settings</div>
                            </div>
                    }
                </Modal>
            </div>
        )
    }
}

const divStyle = {
    padding: '10px',
    width: '90%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: '1px solid #cccccc',
    flexWrap: 'wrap',
    lineHeight: '20pt'
}

const btnStyle = {
    maxWidth: '250px',
    height: "30px",
    background: colors.color2,
    border: "none",
    borderRadius: "5px",
    color: colors.color5,
    fontWeight: "bold",
    cursor: "pointer",
    marginRight: '20px',
    marginTop: '5px'
}

export default Item
