import React, {Component} from 'react'
import config from '@utils/config.js'
import Loading from '@common/Loading'
import Slide from './Slide'
import Menu from './Menu'
import pattern from './pattern.png'

const myItems = [
    {
        id: 1,
        imgUrl: "/uploads/slide1.png",
        title: "FCRLab vince la challenge “City Enabler Select for Cities”",
        link: "/news/node/1",
        description: "Lorem ipsum dolor sit amet consectetuer eros vivamus porta interdum venenatis."
    }
]

class Slideshow extends Component {
    state = {
        backgroundImage: '#222288',
        items: myItems,
        slide: 0,
        loading: true
    }

    changeSlide = () => {
        let nextSlide = (this.state.slide+1) % this.state.items.length;
        this.setState(prevState => ({ slide: nextSlide }) );
    }

    changeSlideById = (id) => {
        let index = this.state.items.findIndex(x => x._id === id);
        this.setState( {slide: index } )
        clearInterval(this.interval);
        this.interval = setInterval(() => this.changeSlide(), 4000);
    }

    componentDidMount() {
        let url = config.serverUrl + "/api/node?fields=title,slug,description,tags,category,image&limit=5&sort=-1&slideshow=true";
        this.setState({loading: true});
        fetch( url )
            .then(response => response.json())
            .then(data => {
                this.setState({
                    items: data,
                    loading: data ? false : true
                })
            })

        this.interval = setInterval(() => this.changeSlide(), 4000);
      }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        return(
            <div style={ {...slideShowStyle, backgroundColor: this.state.background} }>
                { this.state.loading ? 
                <Loading /> :
                <div style={ {...slideShowStyle, backgroundColor: this.state.background} }>
                    <Slide item={ this.state.items[ this.state.slide ]} loading={this.state.loading} />
                    <Menu items={ this.state.items} current={ this.state.slide } handleClick={ this.changeSlideById } loading={this.state.loading} />
                </div>
               }
               
            </div>
        )
    }
}

const slideShowStyle = {
    backgroundImage: `url(${pattern})`,
    backgroundRepeat: 'repeat',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flex: 1
}

export default Slideshow
