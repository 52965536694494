import React from 'react'
import colors from '@utils/colors'
import { ContextConsumer } from '@utils/Context'
import { Link } from 'react-router-dom'

function Menu() {
    return (
        <div>
            {/* <button className='iconBtn' ><i className="fa fa-2x fa-info-circle" style={{ color: colors.color4 }} title="Info" /></button> */}
            <ContextConsumer>
                {
                    ({ isLogged }) => (
                        isLogged ?
                            <Link to="/settings"><button className='iconBtn' ><i className="fa fa-2x fa-user" style={{ color: colors.color4 }} /></button></Link> : null
                    )}
            </ContextConsumer>
        </div>
    )
}

export default Menu