import React, { Component } from 'react'
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

class Page2 extends Component {
    render() {
        let {lineHeight} = this.props;
        return (
            <div>
                <h2>Set the line</h2>
                {this.props.blob ? <p>Set the line at the right position</p> : null}
                <div style={{ display: 'flex', alignItems: 'flex-end', background: 'white' }}>
                    <img src={this.props.imageUrl} style={{backgroundColor: 'rgba(52, 52, 52, alpha)'}} alt="Signature" width="300px" />
                    <div style={{ ...hrStyle, height: lineHeight }}>
                        <hr style={{
                            margin: 0, height: '3px',
                            color: 'black',
                            backgroundColor: 'black',
                            border: 'none'
                        }} />
                    </div>
                    <div style={{ width: '20px', height: '100px', display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                        <Slider
                            min={0}
                            max={100}
                            vertical={true}
                            onChange={this.props.sliderChange}
                            value={lineHeight}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

const hrStyle = {
    width: '300px',
    position: 'absolute',
    overflow: 'hidden',
    zIndex: '0',
    padding: '0'
}

export default Page2