import React, {Component} from 'react'
import { Marker } from '@react-google-maps/api'

var timer; 
var orionUrl = "http://fiware-orion.unime.it:443"

class BusMarker extends Component {
    state = {
		bus: <Marker
        //onClick={((e) => this.props.selectStep(index))}
        position={{
            lat: parseFloat(24.342),
            lng: parseFloat(38.324)
        }}
    //    icon={iconMarker}
    />
    }
    
    componentWillUnmount() {
		if (timer) clearInterval(timer);
		this.setState({ bus: null })
	}

    updateBus(bus) {
		if (timer) clearInterval(timer);
		timer = setInterval(() => {
			fetch(orionUrl + "/v2/entities/" + bus + "?options=keyValues", {
				method: 'GET',
				headers: { 'fiware-service': 'berlino', 'fiware-servicepath': '/bus' }
			})
				.then(response => response.json())
				.then(data => {
					let iconMarker = new window.google.maps.MarkerImage(
						"https://cdn0.iconfinder.com/data/icons/education-flat-7/128/17_Scholl_Bus-512.png",
						null, /* size is determined at runtime */
						null, /* origin is 0,0 */
						null, /* anchor is bottom center of the scaled image */
						new window.google.maps.Size(32, 32)
					);
					let x = data.location.split(",")
					console.log(x)
					this.setState({
						bus: <Marker
							//onClick={((e) => this.props.selectStep(index))}
							position={{
								lat: parseFloat(x[1]),
								lng: parseFloat(x[0])
							}}
							icon={iconMarker}
						/>
					})
				})
		}, 5000);
	}

    render() {
        let bus = this.state.bus
        return (
            <div>
            {bus}
            </div>
        )
    }
}

export default BusMarker