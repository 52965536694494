import React from "react"
import pattern from '../pattern.png'

function SectionFooter() {
    return (
        <div style={footerStyle}>

        </div>
    )
}

const footerStyle = {
    display: 'none',
    backgroundImage: `url(${pattern})`,
    backgroundRepeat: 'repeat',
    height: 'auto',
    minHeight: '5px',
    bottom: '0px'
}

export default SectionFooter