import React, {Component} from 'react'
import { Link } from 'react-router-dom'
import ReCAPTCHA from 'react-google-recaptcha'
import config from '@utils/config'
import { Context } from '@utils/Context'
import {notify} from '@utils/notifications'
import colors from '@utils/colors'

class Form extends Component {
    static contextType = Context;

    state = {
        email: "",
        password: "",
        verify: "",
        captcha: "",
        newsletter: false,
        agree: false
    }

    onCaptchaChange = (value) => {
        this.setState({captcha: value});
    }

    handleChange = (event) => {
        const {name, value, type, checked} = event.target
        type === "checkbox" ? this.setState({ [name]: checked }) : this.setState({ [name]: value })
    }

    handleSubmit = (event, fun) => {
        event.preventDefault();
          let email = this.state.email,
            password = this.state.password, 
            password2 = this.state.verify,
            captcha = this.state.captcha,
            newsletter = this.state.newsletter,
            agree = this.state.agree,
            data;  

        //notify("Warning", "Registration are disabled", "warning", "top-center");
        data = { "user": {email, password}, "info": {newsletter, agree}}//captcha };
        if(password === password2)
        {
            if(captcha)
            {
                fetch(config.serverUrl + '/users/createUser', {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify(data)
                })
                .then( (res) => res.json() )
                .then( (res) => {
                    if(res.status === 201)
                    {
                        notify("Account",  "User created.", "success", "top-center");
                        this.context.login(this.state.email, this.state.password, false);
                    }
                    else
                    {
                        let message =  res.status || res.error.code + ": " +  res.error.message || res.body;
                        notify("Error", message, "warning", "top-center");
                    }
                });
            }
            else
                notify("Error",  "You have to use reCAPTCHA.", "warning", "top-center");
        }
        else
            notify("Error",  "Your passwords does not match.", "warning", "top-center");
      
    }

    render() {
        return (
            <div style={{padding: '20px'}}>
                <form style={formStyle} onSubmit={this.handleSubmit}>
                    <h1>Sign up</h1>
                    <h3>Email address</h3>
                    <input 
                        type="email" 
                        name="email" 
                        placeholder="example@example.it" 
                        onChange={this.handleChange} 
                        value={this.state.username}
                        required 
                    />
                    <h3>Password</h3>
                    <input 
                        type="password" 
                        name="password" 
                        placeholder="Your password" 
                        onChange={this.handleChange} 
                        value={this.state.password}
                        required 
                    />  
                    <input 
                        type="password" 
                        name="verify" 
                        placeholder="Verify your password" 
                        onChange={this.handleChange} 
                        value={this.state.verify}
                        required 
                    />  

                    <br />
                    <ReCAPTCHA
                        sitekey="6LdTRLcUAAAAAIvnYDmuXCvh0T1EY4cAD4i1tJeq"
                        onChange={this.onCaptchaChange}
                        size="compact"
                    />

                    <div style={{marginTop: "10px", display: "flex"}}>
                        <input type="checkbox" name="newsletter" checked={this.state.newsletter} onChange={this.handleChange} />
                        <div>I agree to reveice newsletters from FCRLab.</div>
                    </div>
                    <div style={{marginTop: "10px", display: "flex"}}>
                        <input type="checkbox" name="agree" checked={this.state.agree} onChange={this.handleChange} required />
                        <div>I agree to <Link to="/terms">Terms of Use</Link> and <Link to="/privacy">Privacy Policy</Link>.</div>
                    </div>

                    <button style={btnStyle}>Sign up</button>
                    <div style={{display: 'flex', alignItems:"center", justifyContent: 'center', width:"100%"}}>
                        <Link to="/signup">You already have an account?</Link>
                    </div>
                </form>
            </div>
        )

    }
}

Form.contextType = Context;

const formStyle= {
    display: 'flex', 
    flexDirection: 'column',
    alignItems: 'left', 
    justifyContent: 'center',
    width: '20%',
    minWidth: '280px',
    background: colors.color5,
    borderRadius: "5px",
    padding: '40px 5% 40px 5%',
    margin: '20px auto 20px auto'
}

const btnStyle = {
    width: "100%",
    height: "40px",
    margin: "20px 0px",
    background: colors.color2,
    border: "none",
    borderRadius: "5px",
    color: colors.color5,
    fontWeight: "bold",
    cursor: "pointer"
}

export default Form
