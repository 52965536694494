import React, {Component} from 'react'
import Section from '@common/Section'
import Loading from '@common/Loading'
import UserPage from './UserPage'
import config from '@utils/config'

class User extends Component {
    state = {
        loading: true,
        user: null
    }

    componentDidMount() {
        let id = window.location.pathname.split("user/")[1],
            url = config.serverUrl + "/users/getUserInfo/" + id;
        this.setState({ loading: true });
        fetch(url)
            .then(response => response.json())
            .then(data => {
                console.log(data)
                this.setState({
                    user: data,
                    loading: false,
                })
            })
    }

    render() {
        let {loading, user} = this.state,
            content = loading ? <Loading /> : <UserPage user={user} />,
            title = user ? user.name + " " + user.surname : "User"
        return (
            <Section content={[
                {content: content, title, flex: "1", minWidth: "200px"}
            ]} />
        )
    }
}

export default User