import React, { Component } from 'react'
import config from '@utils/config'
import SaveButton from '@common/SaveButton'
import { notify } from '@utils/notifications'

class UserSocials extends Component {
    state = {
        id: "",
        googleScholar: "",
        researchGate: "",
        linkedin: "",
        facebook: "",
        twitter: "",
        telegram: "",
        loading: false,
        status: 'noChanges'
    }

    handleChange = (event) => {
        if (this.state.status !== 'unsaved')
            this.setState({ status: 'unsaved' });
        const { name, value, type, checked } = event.target
        type === "checkbox" ? this.setState({ [name]: checked }) : this.setState({ [name]: value })
    }

    handleSubmit = (event) => {
        let id = this.state.id,
            googleScholar = this.state.googleScholar,
            researchGate = this.state.researchGate,
            linkedin = this.state.linkedin,
            facebook = this.state.facebook,
            twitter = this.state.twitter,
            telegram = this.state.telegram,
            data;

        this.setState({ status: 'loading' });
        event.preventDefault();

        data = { socials: { googleScholar, researchGate, linkedin, facebook, twitter, telegram } }

        fetch(config.serverUrl + '/users/updateUser/socials/' + id, {
            method: 'PATCH',
            headers: {
                'access-token': sessionStorage.getItem('access_token'),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then((res) => {
                if (res.status === 200) {
                    notify("Success!", res.message, "success", "top-center");
                    this.setState({ status: 'saved' });
                }
                else {
                    this.setState({ status: 'error' });
                    notify("Error!", res.body, "warning", "top-center");
                }
            })
            .catch(error => {
                this.setState({ status: 'error' });
                notify("Error", JSON.stringify(error), "warning", "top-center")
            })
    }

    componentDidMount() {
        let data = this.props.user.socials;
        this.setState({ loading: true });

        if (data) {
            this.setState({
                id: data._id,
                googleScholar: data.googleScholar || "",
                researchGate: data.researchGate || "",
                linkedin: data.linkedin || "",
                researchId: data.researchId || "",
                facebook: data.facebook || "",
                twitter: data.twitter || "",
                telegram: data.telegram || "",
                loading: false,
            })
        }
    }

    render() {
        return (
            <div style={{ background: 'white', height: '100%', display: 'flex', justifyContent: 'center' }}>
                <form style={formStyle} onSubmit={this.handleSubmit}>
                    <h1>Edit your socials links</h1>
                    <label style={labelStyle}>Google Scholar:</label>
                    <input
                        type="text"
                        name="googleScholar"
                        placeholder="https://scholar.google.com"
                        onChange={this.handleChange}
                        value={this.state.googleScholar}
                    />
                    <label style={labelStyle}>ResearchGate:</label>
                    <input
                        type="text"
                        name="researchGate"
                        placeholder="https://www.researchgate.net"
                        onChange={this.handleChange}
                        value={this.state.researchGate}
                    />
                    <label style={labelStyle}>Linkedin:</label>
                    <input
                        type="text"
                        name="linkedin"
                        placeholder="https://it.linkedin.com"
                        onChange={this.handleChange}
                        value={this.state.linkedin}
                    />
                    <label style={labelStyle}>Twitter:</label>
                    <input
                        type="text"
                        name="twitter"
                        placeholder="https://twitter.com"
                        onChange={this.handleChange}
                        value={this.state.twitter}
                    />
                    <label style={labelStyle}>Facebook:</label>
                    <input
                        type="text"
                        name="facebook"
                        placeholder="https://facebook.it"
                        onChange={this.handleChange}
                        value={this.state.facebook}
                    />
                    <br />< br />
                    <label style={labelStyle}>Telegram:</label>
                    <input
                        type="text"
                        name="telegram"
                        placeholder="Telegram username"
                        onChange={this.handleChange}
                        value={this.state.telegram}
                    />
                    <SaveButton status={this.state.status} />
                </form>
            </div>
        )
    }
}

const formStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    width: '100%%',
    minWidth: '280px',
    maxWidth: '700px',
    padding: '40px 5% 40px 5%',
}

const labelStyle = {
    marginTop: '15px'
}

export default UserSocials