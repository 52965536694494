import React, { Component } from 'react'
import CropImage from '@common/CropImage'

class Page1 extends Component {
    render() {
        return (
            <div>
                <h2>Upload your signature image</h2>
                <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                    <CropImage updateCropImage={this.props.updateImage} />
                </div>
                {this.props.blob ? <p>Crop the image with the minimum rectangle that best fit the signature.</p> : null}
            </div>
        )
    }
}

export default Page1