import React from 'react'
import irccs from './images/irccsme.png'
import almadigit from './images/almadigit.png'
import unime from './images/unime.png'
import cust from './images/cust.png'
import alumnime from './images/alumnime.png'
import colors from '@utils/colors'

function Footer() {
    return (
        <div style={ footerStyle } >
            <img style={imgStyle} width="250px" src={almadigit} alt='almadigit' />
            <img style={imgStyle} width="150px" src={unime} alt='unime' />
            <img style={imgStyle} width="200px" src={cust} alt='cust' />
        </div>
    )
}

const footerStyle = {
    width: '100%',
    padding: '10px 0',
    display: "flex",
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-evenly',

    backgroundColor: colors.color6,
    color: colors.color1,
}

const imgStyle = {
    margin: '15px 50px',
}

export default Footer
