import React, {Component} from 'react'
import { Link } from 'react-router-dom'
import { ContextConsumer } from '@utils/Context'
import colors from '@utils/colors'

class User extends Component {
    render() {
        return (
            <div style={divStyle}>
                <ContextConsumer>
                    {
                        ({ isLogged, username, logout }) => (
                            isLogged ? 
                                // eslint-disable-next-line
                                <div>{username} | <a onClick={logout}>Logout</a></div> : 
                                // eslint-disable-next-line
                                <div>
                                    <Link to="/signup" style={linkStyle}>Signup</Link>
                                    {" / "}
                                    <Link to="/login" style={linkStyle}>Login</Link> 
                                </div>
                        )
                    }
                </ContextConsumer>
                
            </div>
        )
    }
}

const divStyle = {
    position: 'absolute',
    top: '0',
    right: '0',
    zIndex: '9',
    backgroundColor: colors.color1,
    color: colors.color5,
    padding: '4px 16px',
    cursor: 'pointer'
}

const linkStyle = {
    textDecoration: 'none',
    color: colors.color5
}

export default User