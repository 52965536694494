import React from 'react'
import PropTypes from 'prop-types'
import SectionComponent from './SectionComponent'
import Separator from '../Separator'

function SimpleSection(props) {
    var divStyle = {
        width: '95%',
        maxWidth: '1300px',
        margin: 'auto',
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        flex: '1',
        writable: true
    }
    let sections = []
    props.content.map( (item,index) => {
        if(index > 0)
            sections.push(<Separator key={props.content+index} width={props.sep} hideOnMobile={true} />)
        sections.push( <SectionComponent key={index} content={item.content} title={item.title} flex={item.flex} minWidth={item.minWidth} /> )
        return null
    })
    divStyle.width = props.width? props.width : divStyle.width
    divStyle.maxWidth = props.maxWidth ? props.maxWidth : divStyle.maxWidth
    return (
        <div style={divStyle} >
            {sections}
        </div>
    )
}


SimpleSection.propTypes = {
    content: PropTypes.array,
    sep: PropTypes.string
};

SimpleSection.defaultProps = {
    sep: '30px'
};

export default SimpleSection