import React, { Component } from 'react'
import config from '@utils/config'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import SaveButton from '@common/SaveButton'
import { notify } from '@utils/notifications'

class VehiclesInfo extends Component {
    state = {
        type: "Car",
        model: "",
        licensePlate: "",
        insuranceCompany: "",
        insuranceNumber: "",
        startInsurance: "",
        endInsurance: "",
        status: 'noChanges'
    }

    changeDate = (key, date) => {
        if (this.state.status !== 'unsaved')
            this.setState({ status: 'unsaved' });
        this.setState({ [key]: date })
    }

    handleChange = (event) => {
        if (this.state.status !== 'unsaved')
            this.setState({ status: 'unsaved' });
        const { name, value, type, checked } = event.target
        type === "checkbox" ? this.setState({ [name]: checked }) : this.setState({ [name]: value })
    }

    handleSubmit = (event, fun) => {
        let id = sessionStorage.getItem('id'),
            { type, model, licensePlate, insuranceCompany, insuranceNumber, startInsurance, endInsurance } = this.state,
            data;

        this.setState({ status: 'loading' });
        event.preventDefault();

        data = {
            vehicles: [{
                type, model, licensePlate, insuranceCompany, insuranceNumber,
                startInsurance: startInsurance.toLocaleString("it-IT", { dateStyle: 'short', year: "numeric" }),
                endInsurance: endInsurance.toLocaleString("it-IT", { dateStyle: 'short', year: "numeric" })
            }]
        }

        fetch(config.serverUrl + '/users/updateUser/' + id, {
            method: 'PATCH',
            headers: {
                'access-token': sessionStorage.getItem('access_token'),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then((res) => {
                if (res.status === 200) {
                    notify("Success!", "User vehicles updated", "success", "top-center");
                    this.setState({ status: 'saved' });
                }
                else {
                    this.setState({ status: 'error' });
                    notify("Error!", res.body, "warning", "top-center");
                }
            })
            .catch(error => {
                this.setState({ status: 'error' });
                notify("Error", JSON.stringify(error), "warning", "top-center")
            })
    }

    componentDidMount() {
        let url = config.serverUrl + "/users/getUserInfo/" + sessionStorage.getItem('id') + "?fields=vehicles";
        this.setState({ loading: true });
        fetch(url)
            .then(response => response.json())
            .then(data => {
                if (Object.keys(data).length > 1) {
                    data = data.vehicles[0];
                    let startInsurance = data.startInsurance ? data.startInsurance.split("/") : ['01', '01', '2019'],
                        endInsurance = data.endInsurance ? data.endInsurance.split("/") : ['01', '01', '2019']
                    if (data)
                        this.setState({
                            type: data.type || "",
                            model: data.model || "",
                            licensePlate: data.licensePlate || "",
                            insuranceCompany: data.insuranceCompany || "",
                            insuranceNumber: data.insuranceNumber || "",
                            startInsurance: new Date(startInsurance[2], startInsurance[1], startInsurance[0]) || "",
                            endInsurance: new Date(endInsurance[2], endInsurance[1], endInsurance[0]) || "",
                            loading: false,
                        })
                }
                else
                    this.setState({ loading: false })
            })
    }

    render() {
        return (
            <div style={{ background: 'white', height: '100%', marginLeft: '5px' }}>
                <form style={formStyle} onSubmit={this.handleSubmit}>
                    <h1>Edit your vehicles info</h1>
                    <div style={{ border: '2px solid #ccc', borderRadius: '10px', marginTop: '10px', padding: '10px', marginRight: '30px' }}>
                        <label style={labelStyle}>Type</label>
                        <select name="type" onChange={this.handleChange} >
                            <option value="car">Car</option>
                            <option value="motorbike">Motorbike</option>
                        </select>
                        <label style={labelStyle}>Model</label>
                        <input
                            type="text"
                            name="model"
                            placeholder="Vehicle model"
                            onChange={this.handleChange}
                            value={this.state.model}
                        />
                        <label style={labelStyle}>License Plate</label>
                        <input
                            type="text"
                            name="licensePlate"
                            placeholder="Your License Plate"
                            onChange={this.handleChange}
                            value={this.state.licensePlate}
                        />
                        <label style={labelStyle}>Insurance Company</label>
                        <input
                            type="text"
                            name="insuranceCompany"
                            placeholder="Your Insurance Company"
                            onChange={this.handleChange}
                            value={this.state.insuranceCompany}
                        />
                        <label style={labelStyle}>Insurance Number</label>
                        <input
                            type="text"
                            name="insuranceNumber"
                            placeholder="Your Insurance Number"
                            onChange={this.handleChange}
                            value={this.state.insuranceNumber}
                        />
                        <label style={labelStyle}>Insurance Validity Period</label>
                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            <DatePicker
                                name="startInsurance"
                                placeholderText="Start Insurance Validity"
                                selected={this.state.startInsurance}
                                onChange={date => this.changeDate('startInsurance', date)}
                                customInput={<input type="text" />}
                                dateFormat="dd/MM/yyyy"
                            />
                            <DatePicker
                                name="endInsurance"
                                placeholderText="End Insurance Validity"
                                selected={this.state.endInsurance}
                                onChange={date => this.changeDate('endInsurance', date)}
                                customInput={<input type="text" />}
                                dateFormat="dd/MM/yyyy"
                            />
                        </div>
                        <SaveButton status={this.state.status} />
                    </div>
                </form>
            </div>
        )
    }
}

const formStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    width: '100%%',
    minWidth: '280px',
    maxWidth: '700px',
    padding: '40px 5% 40px 5%',
}

const labelStyle = {
    marginTop: '15px'
}

export default VehiclesInfo