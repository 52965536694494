import React, {Component} from 'react'
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

class Editor extends Component {
    state = {
        editor: null,
        loading: true
    }

    updateBody() {
        this.state.editor.setData(this.props.body)
    }

    render() {  
        return (
            <div className="App" >
                <CKEditor
                    editor={ ClassicEditor }
                    onInit={ editor => {
                        // You can store the "editor" and use when it is needed.
                        this.setState({editor})      
                        editor.ui.view.editable.element.style.minHeight = "300px"
                    } }
                    onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        this.props.updateBody( { body: data } )
                    } }
                />
            </div>
        )
    }
}

export default Editor