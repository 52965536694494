import React, {Component} from 'react'
import colors from "@utils/colors"
import config from '@utils/config.js'
import Loading from '@common/Loading'
import NewsItem from './NewsItem';

class NewsList extends Component {
    state = {
        news: [],
        loading: true
    }

    componentDidMount() {
        let url = `${config.serverUrl}/api/node/category/call?fields=title,slug,description,tags,category,shortTitle,date,place&sort=-1`;
        this.setState({loading: true});
        fetch( url )
            .then(response => response.json())
            .then(data => {
                this.setState({
                    loading: false,
                    news: data
                })
            })
    }

    render() {
        const items = this.state.loading ? 
                <Loading width="95%" height="400px" title="" />
                :
                this.state.news.map( item => <NewsItem key={item._id} content={item} />  
        )
        return (
            <div style={{background: colors.color6, display: "flex", flexDirection: "column"}}>
                {items}
            </div>
        )
    }
}

export default NewsList
