import React, { Component } from 'react'
import Steps from './Steps'
import SelectedStep from './SelectedStep'

class SubscribeForm extends Component {
    state = {
        selectedStep: null
    }

    selectStep = (n) => {
        this.setState({ selectedStep: n })
    }
    
    render() {
        return (
            <div>
                {this.state.selectedStep === null ?
                    this.props.line ?
                        <Steps
                            handleState={this.props.handleState}
                            line={this.props.line}
                            selectStep={this.selectStep}
                        />
                        :
                        <div style={{ height: '400px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <h1>Select a line</h1>
                        </div>
                    :
                    <SelectedStep
                        lineId={this.props.line._id}
                        step={this.props.line.steps[this.state.selectedStep]}
                        selectStep={this.selectStep}
                    />

                }
            </div>
        )
    }
}

export default SubscribeForm