import React from 'react'
import {Link} from 'react-router-dom'
import fcrlab from './fcrlab.png'

function Title(props) {
    
    return(
        <div style={{position: 'absolute', left: '70px', display: 'flex', alignItems: 'left',}}>
           <Link to="/" >
               <img className='hideOnMobile' src={fcrlab} height="40px" alt="FCRLab" />
            </Link>
        </div>
    )
}

export default Title