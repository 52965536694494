import React, {Component} from 'react'
import Socials from '@common/Socials'
import Section from '@common/Section'
import SimpleSection from '@common/Section/SimpleSection'
import Slideshow from './Slideshow'
import Projects from './Projects'
import Research from './Research'

class HomePage extends Component {
    render() {
        return(
            <div style={{top: "200px", display: "flex", flexDirection: "column"}}>
		        <Slideshow />
                
                <SimpleSection content={[
                    {content: <Projects />, title: "RECENT PROJECT", flex: "1", minWidth: "200px"}
                ]} width="100%" maxWidth="100%" />

                <center style={{marginTop: "30px"}}>
                    <hr />
                    <h2 style={{width: '100%', maxWidth:'800px', textAlign: 'center', margin: "10px 0 10px 0"}}>
                        The Future Computing Research Laboratory at Unime conducts advanced scientific research in the field of innovative ICT solutions.
                    </h2>
                    <hr />
                </center>

                <Section content={[
                    {content: <Research key="0" />, title: "", flex: "3", minWidth: "300px"},
                    {content: <Socials key="1" />, title: "", flex: "1", minWidth: "300px"}
                ]} />

            </div>
        )
    }
}

export default HomePage
