import React, {Component} from 'react'
import Menu from './Menu'
import Home from './pages/Home'
import SignDocuments from './pages/SignDocuments'
import Reports from './pages/Reports'
import TimeReports from './pages/TimeReports'
import MissionRequest from './pages/MissionRequest'
import ProtectedComponent from '@common/ProtectedComponent'
import colors from '@utils/colors'

const pages = [<Home />, <SignDocuments />, <Reports />, <TimeReports />, <ProtectedComponent render={<MissionRequest />} roles={["admin", "fcrlab"]} />];

const services = [
    {id: 1, title: "Sign Documents", icon: "info-circle"},
    {id: 2, title: "Reports", icon: "info-circle"}, 
    {id: 3, title: "TimeReports", icon: "gg-circle"},
    //{id: 4, title: "Mission Request", icon: "grav"}
];

class Services extends Component {
    state = {
        menuToggle: false,
        menuShowed: true,
        selected: 0
    }

    componentDidMount = () => {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount = () => {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions = () => {
        if( window.innerWidth <= 600)
            this.setState({ menuToggle: true });
        else
        this.setState({ menuToggle: false });
    }

    handleMenu = () => {
        this.setState({menuShowed: !this.state.menuShowed});
    }

    handleClick = (id) => {
        this.setState({selected: id, menuShowed: !this.state.menuShowed});
    }

    render() {
        let content;
        if( this.state.menuToggle )
            content = this.state.menuShowed ? 
                <div style={{display: 'flex', width: '100%' }}>
                    <Menu handleClick={this.handleClick} selected={this.state.selected} services={services} />
                    <div onClick={this.handleMenu} style={{cursor: 'pointer', padding: '16px', background: colors.color1, color: colors.color6}}>
                        <i className="fa fa-2x fa-list" aria-hidden="true"></i>
                    </div>
                </div> : 
                <div style={{display: 'flex', width: '100%'}}>
                    <div style={{flex: '6', background: colors.color6}}>{pages[this.state.selected]}</div>
                    <div style={{flex: '0', display: 'flex', justifyContent: 'center', padding: '15px', background: colors.color1, color: colors.color6}}>
                        <div onClick={this.handleMenu} style={{cursor: 'pointer'}}><i className="fa fa-2x fa-list" aria-hidden="true"></i></div>
                    </div>
                </div>
        else
            content = <div style={divStyle}>
                <Menu handleClick={this.handleClick} selected={this.state.selected} services={services} />
                <div style={{flex: '5', background: colors.color6}}> {pages[this.state.selected]} </div>
            </div>
        return (
            <div style={divStyle}>
                {content}
                <div style={{ background: colors.color4, height: '10px', width: '70%'}}></div>
            </div>
        )
    }
}

const divStyle = {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexWrap: 'wrap'
}

export default Services
