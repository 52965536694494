import React, {Component} from 'react'

class Tags extends Component {
    state = {
        focused: false,
        input: ''
    }

    render() {
        const styles = {
            items: {
                display: 'inline-block',
                cursor: 'pointer',
                padding: "7px",
                background: "#cccccc",
                marginRight: "5px",
                marginTop: "5px",
                fontSize: "11pt"
            },
        };
        let tags;
        if(this.props.tags)
            tags = this.props.tags.map((item, i) => 
                <li key={i} style={styles.items} onClick={this.handleRemoveItem(i)}>
                    {item}
                    <i style={{marginLeft: "5px"}} className="fa fa-times" aria-hidden="true"></i>
                </li>
            );
        return (
            <label>
                <div>
                    <input
                        type="text"
                        placeholder="Add a new tag"
                        value={this.state.input}
                        onChange={this.handleInputChange}
                        onKeyDown={this.handleInputKeyDown} 
                    />

                    {tags}
                </div>
            </label>
        );
    }

    handleInputChange = (evt) => {
        let input = (evt.target.value === " " || evt.target.value === ",") ? '' : evt.target.value
        this.setState({ input });
    }

    handleInputKeyDown = (evt) => {
        if ( evt.keyCode === 32 || evt.keyCode === 188) {
            if (evt.target.value)
            {
                const {value} = evt.target;
                this.props.updateTags([...this.props.tags, value])
                this.setState({input: ''})
            }
        }

        if ( this.props.tags.length && evt.keyCode === 8 && !this.state.input.length ) {
            this.props.updateTags(
                this.props.tags.slice(0, this.props.tags.length - 1)
            );
        }
    }

    handleRemoveItem = (index) => {
        return () => {
            this.props.updateTags(
                this.props.tags.filter((item, i) => i !== index)
            );
        }
    }
}

export default Tags