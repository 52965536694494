import React, { Component } from 'react'
import config from '@utils/config.js'
import { ContextConsumer } from '@utils/Context'
import SimpleSection from '@common/Section/SimpleSection'
import Section from '@common/Section'
import Actions from '@common/Actions'
import Loading from '@common/Loading'
import NodeBody from './NodeBody'
import NodeInfo from './NodeInfo'


class Node extends Component {
    state = {
        node: {},
        loading: true
    }

    componentDidMount() {
        let id = window.location.pathname.split("/").pop();
        let url = config.serverUrl + "/api/node/" + id;
        this.setState({loading: true});
        fetch( url )
            .then(response => response.json())
            .then(data => {
                this.setState({
                    loading: false,
                    node: data
                })
            })
    }

    render() {
        const content1 = this.state.loading ? 
            [
                {content: <Loading width="95%" height="400px" title="" />, title: "", flex: "3", minWidth: "300px"},
                {content: <Loading width="95%" height="400px" title="" />, title: "", flex: "1", minWidth: "300px"}
            ]
            :
            [
                {content: <NodeBody key="0" node={this.state.node} />, title: "", flex: "3", minWidth: "300px"},
                {content: <NodeInfo key="1" node={this.state.node} />, title: "", flex: "1", minWidth: "300px"}
            ]
        return(
            <div>
                <ContextConsumer>
                    {
                        ({ isLogged }) => (
                            isLogged ? 
                                <SimpleSection 
                                    content={[
                                        {
                                            content: <Actions content={[
                                                {link:"/news/edit/"+this.state.node._id, title:"Edit"},
                                            ]} />, 
                                            flex: "1", 
                                            minWidth: "200px"
                                        }
                                    ]}
                                /> : 
                                null
                        )
                    }
                </ContextConsumer>
                <Section content={content1} />
            </div>
        )
    }
}

export default Node
