import React from 'react'
import { withRouter } from "react-router";

import Navbar from './Navbar'
import User from './User'

function PreHeader(props) {
    return (
        <div>
            <Navbar location={ props.history.location.key } />
            <User />
        </div>
    )
}

export default withRouter(PreHeader)