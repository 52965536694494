import React from 'react'

function SectionComponent(props) {
    return (
        <div style={{
                flex: props.flex,
                //maxWidth: '100%',
                minWidth: props.minWidth,
                margin: '30px 0px 0px 0px',
                textAlign: 'center'
        }}>
            <div>
                {props.content}
            </div>
        </div>
    )
}

export default SectionComponent