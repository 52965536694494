import React, { Component } from 'react'
import Loading from '@common/Loading'

class Page3 extends Component {
    render() {
        return (
            <div>
                {this.props.loading ?
                    <Loading />
                    :
                    <div>
                        <h2>Set your passphrase</h2>
                        <input
                            name='passphrase'
                            type='password'
                            value={this.props.passphrase}
                            onChange={(e) => this.props.setPassphrase(e.target.value)}
                        />
                    </div>
                }
            </div>
        )
    }
}

export default Page3