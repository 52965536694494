import React, {Component} from 'react'
import Item from './Item'
import colors from '@utils/colors'
import '../style.css'


class Menu extends Component {
    
    render() {
        let i = 0;

        const items = this.props.items.map( item => {
            let link = item.category === "call" ? `/calls/${item.slug}` : `/news/${item.slug}`;
            return(
            <Item 
                title={item.title} 
                key={i++} 
                id={item._id} 
                link={link}
                selected={item === this.props.items[this.props.current]} 
                handleClick={ this.props.handleClick } 
            />)}
        )
        return(     
            <div style={menuStyle} id="menuSlideshow">
                <div style={itemStyle}>
                    {items}
                </div>  
            </div>
        )
    }
}

const menuStyle = {
    backgroundColor: colors.color1,
    maxWidth: '300px',
    maxHeight: '600px',
    padding: '10px 7px 7px 7px',
    flex: 3
}

const itemStyle = {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '600px'
}

export default Menu
