import React, { Component } from 'react'

class Form extends Component {

    selectTransport = (event) => {
        const { value } = event.target
        this.props.setTransport(value)
        this.props.getLines();
    }

    setLine = (event) => {
        const { value } = event.target
        this.props.setLine(value)
    }

    render() {
        let lineOptions = this.props.state.lines.map((item, index) => <option value={index} key={index}>{item.name}</option>)
        return (
            <div style={{ padding: "10px" }}>
                <div style={{ textAlign: 'left', display: 'flex', width: '100%', flexWrap: 'wrap', justifyContent: "space-between" }}>
                    <div style={{ flex: 1, minWidth: '170px', padding: '0 10px' }}>
                        <h3 style={{ margin: 0 }}>City</h3>
                        <select disabled>
                            <option>Messina</option>
                        </select>
                    </div>
                    <div style={{ flex: 1, minWidth: '170px', padding: '0 10px' }}>
                        <h3 style={{ margin: 0 }}>Means of transport</h3>
                        <select name="meansOfTransport" value={this.props.state.meansOfTransport} onChange={this.selectTransport}>
                            <option value="">-</option>
                            <option value="bus">Bus</option>
                        </select>
                    </div>
                    <div style={{ flex: 1, minWidth: '170px', padding: '0 10px' }}>
                        {
                            this.props.state.meansOfTransport === "bus" && this.props.state.lines.length > 0 ?
                                <div>
                                    <h3 style={{ margin: 0 }}>Line</h3>
                                    <select name="selectedLine" value={this.props.state.selectedLine} onChange={this.setLine}>
                                        <option value={-1}>-</option>
                                        {lineOptions}
                                    </select>
                                </div>
                                :
                                null
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default Form