import React from 'react'

function FormSection(props) {
    return (
        <div style={{ border: '2px solid #ccc', borderRadius: '10px', marginTop: '10px', padding: '10px' }}>
            <center><h4>{props.title}</h4></center><hr /><br />
            <div>
                {props.children}
            </div>
        </div>
    )
}

export default FormSection;