import React, { Component } from 'react'
import { Redirect } from 'react-router';
import ProtectedComponent from '@common/ProtectedComponent'
import Menu from './Menu'
import UserInfo from './pages/UserInfo'
import UserSocials from './pages/UserSocials'
import UserSettings from './pages/UserSettings'
import Signatures from './pages/Signatures'
import VehiclesInfo from './pages/VehiclesInfo'
import Logs from './pages/Logs'
import Team from './pages/Team'
import ResearchCollaborator from './pages/ResearchCollaborator'
import colors from '@utils/colors'
import { ContextConsumer } from '@utils/Context'


const pages = [<UserInfo />, <UserSocials />, <UserSettings />,
    <ProtectedComponent render={<Signatures />} roles={["admin", "fcrlab"]} />,
    <ProtectedComponent render={<VehiclesInfo />} roles={["admin", "fcrlab"]} />,
    <div>Users</div>,
    <ProtectedComponent render={<Team />} roles={["admin"]} />,
    <ProtectedComponent render={<ResearchCollaborator />} roles={["admin"]} />,
    <ProtectedComponent render={<div>Projects</div>} roles={["admin"]} />,
    <ProtectedComponent render={<div>News</div>} roles={["admin"]} />,
    <ProtectedComponent render={<Logs />} roles={["admin"]} />];

const userSettings = [
    { id: 0, title: "User info", icon: "info-circle" },
    { id: 1, title: "User socials", icon: "gg-circle" },
    { id: 2, title: "User settings", icon: "pencil-square" }
];

const servicesInfo = [
    { id: 3, title: "Signatures", icon: 'key' },
    { id: 4, title: "Vehicles Info", icon: 'automobile' }
]

const adminSettings = [
    //{ id: 5, title: "Users", icon: "user-circle" },
    { id: 6, title: "Team", icon: "users" },
    { id: 7, title: "ResearchCollaborator", icon: "users" },
    //{ id: 8, title: "Projects", icon: "tasks" },
    //{ id: 9, title: "News", icon: "map" },
    { id: 10, title: "Logs", icon: "tasks" },
];

class Settings extends Component {
    state = {
        menuToggle: false,
        menuShowed: true,
        selected: 0
    }

    componentDidMount = () => {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount = () => {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions = () => {
        if (window.innerWidth <= 600)
            this.setState({ menuToggle: true });
        else
            this.setState({ menuToggle: false });
    }

    handleMenu = () => {
        this.setState({ menuShowed: !this.state.menuShowed });
    }

    handleClick = (id) => {
        this.setState({ selected: id, menuShowed: !this.state.menuShowed });
    }

    render() {
        let content;
        if (this.state.menuToggle)
            content = this.state.menuShowed ?
                <div style={{ display: 'flex', width: '100%' }}>
                    <Menu
                        handleClick={this.handleClick}
                        selected={this.state.selected}
                        userSettings={userSettings}
                        servicesInfo={servicesInfo}
                        adminSettings={adminSettings}
                    />
                    <div onClick={this.handleMenu} style={{ cursor: 'pointer', padding: '16px', background: colors.color1, color: colors.color6 }}>
                        <i className="fa fa-2x fa-list" aria-hidden="true"></i>
                    </div>
                </div> :
                <div style={{ display: 'flex', width: '100%' }}>
                    <div style={{ flex: '6', background: colors.color6 }}>{pages[this.state.selected]}</div>
                    <div style={{ flex: '0', display: 'flex', justifyContent: 'center', padding: '15px', background: colors.color1, color: colors.color6 }}>
                        <div onClick={this.handleMenu} style={{ cursor: 'pointer' }}><i className="fa fa-2x fa-list" aria-hidden="true"></i></div>
                    </div>
                </div>
        else
            content = <div style={divStyle}>
                <Menu
                    handleClick={this.handleClick}
                    selected={this.state.selected}
                    userSettings={userSettings}
                    servicesInfo={servicesInfo}
                    adminSettings={adminSettings}
                />
                <div style={{ flex: '5', background: colors.color6 }}> {pages[this.state.selected]} </div>
            </div>
        return (
	     <ContextConsumer>
            {
                ({ isLogged }) => (
                    isLogged ? 
		       <div style={divStyle}>
		        {content}
			<div style={{ background: colors.color4, height: '10px', width: '100%' }}></div>
		       </div> :
                        // eslint-disable-next-line
                        <Redirect to='/'  /> 
                )
            }
        </ContextConsumer>
        )
    }
}

const divStyle = {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexWrap: 'wrap'
}

export default Settings

