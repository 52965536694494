import React, { Component } from 'react'
import Section from '@common/Section'
import Team from './Team'
import ResearchCollaborator from './ResearchCollaborator'

class TeamPage extends Component {
    render() {
        return (
            <div style={{height: '100%'}}>
                <Section content={[
                    { content: <Team />, title: "FCRLab Team", flex: "1", minWidth: "200px" }
                ]} />
                <Section content={[
                    { content: <ResearchCollaborator />, title: "Research Collaborator", flex: "1", minWidth: "200px" }
                ]} />
            </div>
        )
    }
}

export default TeamPage