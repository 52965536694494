import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import Item from './Item'
import BulkSign from './BulkSign'
import config from '@utils/config'

class SignDocuments extends Component {
    state = {
        documents: []
    }

    handleChange = (event) => {
        if (this.state.status !== 'unsaved')
            this.setState({ status: 'unsaved' });
        const { name, value, type, checked } = event.target
        type === "checkbox" ? this.setState({ [name]: checked }) : this.setState({ [name]: value })
    }

    reflesh = () => {
        let url = config.serverUrl + "/api/services/documentToSign/" + sessionStorage.getItem('id');
        fetch(url, { headers: { 'access-token': sessionStorage.getItem('access_token') } })
            .then(response => response.json())
            .then(data => {
                if (data)
                    this.setState({ documents: data })
            })
    }

    componentDidMount() {
        this.reflesh();
    }

    render() {
        let id = sessionStorage.getItem('id'),
            { documents } = this.state,
            waitingSigns = [],
            lastSignedDocuments = [],
            bulkSignDocuments = []

        /*         documents.map((document, i) => {
                    let index = document.requiredSignatures.findIndex(
                        (item) => item.userId === id
                    )
                    if (index === document.nextSignature){
                        waitingSigns.push(<Item item={document} key={i} reflesh={this.reflesh} nextSignature={document.nextSignature} toSign={true} />)
                        bulkSignDocuments.push(document)
                    }
                    else if (index < document.nextSignature)
                        lastSignedDocuments.push(<Item item={document} key={i} reflesh={this.componentDidMount} nextSignature={document.nextSignature} toSign={false} />)
                    return null
                }) */
        documents.forEach((document, i) => {
            document.requiredSignatures.forEach((sign, index) => {
                if (sign.userId === id) {
                    if (index === document.nextSignature) {
                        waitingSigns.push(<Item item={document} key={(i+1)*(index+1)} reflesh={this.reflesh} nextSignature={document.nextSignature} toSign={true} />)
                        bulkSignDocuments.push(document)
                    }
                    else if (index < document.nextSignature)
                        lastSignedDocuments.push(<Item item={document} key={(i+1)*(index+1)} reflesh={this.componentDidMount} nextSignature={document.nextSignature} toSign={false} />)
                }
            });
        })
        return (
            <div style={containerStyle}>
                <h1>Sign Documents</h1>
                <div style={{ width: '90%' }}><hr /></div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ ...labelStyle, background: 'orange', display: 'flex', flexWrap: 'wrap' }}>Waiting to be signed</div>
                    {waitingSigns.length > 1 ? <BulkSign items={bulkSignDocuments} refleshList={this.reflesh} /> : null}
                </div>
                {waitingSigns.length > 0 ? waitingSigns : "No documents in queue"}
                <div style={{ ...labelStyle, background: 'green' }}>Last signed documents</div>
                {lastSignedDocuments.length > 0 ? lastSignedDocuments : "No documents"}
            </div>
        )
    }
}

const containerStyle = {
    background: '#ffffff',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    height: '100%',
    minWidth: '280px',
    marginLeft: '2px',
    padding: '40px 5% 40px 5%',
}

const labelStyle = {
    textAlign: 'center',
    padding: '5px 10px',
    maxWidth: '180px',
    borderRadius: '5px',
    fontWeight: 'bold',
    margin: '10px 0px'
}

export default withRouter(SignDocuments);