import { store } from 'react-notifications-component';

export function notify(title, message, type, position, width=250) {
    store.addNotification({
        title: title,
        message: message,
        type: type,
        container: position,
        width: width,
        insert: "center",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "bounceOut"],
        dismiss: {
          duration: 3000,
        },
        isMobile: true
    });
}