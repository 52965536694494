import React from 'react'

function Item(props) {
    return (
        <div style={itemStyle}>
            <h2><i className={props.icon} /> { props.title } </h2>
            <p> { props.text } </p>
        </div>
    )
}

const itemStyle = {
    width: '250px',
    textAlign: 'center',
    lineHeight: '150%'
}

export default Item