import React from 'react'
import config from '@utils/config'
import colors from '@utils/colors'
/* import { Link } from 'react-router-dom' */

const socialsIcon = {
    googleScholar: 'ai ai-google-scholar',
    researchGate: 'ai ai-researchgate',
    twitter: 'fa fa-twitter',
    linkedin: 'fa fa-linkedin',
    website: 'fa fa-chevron-right'
}

function Item(props) {
    let item = props.content,
        socials = item.socials,
        socialsItems = []

    for (let x in socials) {
        if (socialsIcon[x])
            if (socials[x])
                socialsItems.push(
                    <div style={socialsStyle} key={x}>
                        <a href={socials[x]} target="_blank" rel="noopener noreferrer">
                            <i className={socialsIcon[x]} style={{color: colors.color3}} />
                        </a>
                    </div>
                )
    }
    try {
       var email = socials.email ? <i><br/>{socials.email}</i> : null;
    } catch {}
    return (

        <div style={{ padding: '10px 20px', margin: '10px', borderRadius: '20px', border: '1px solid #cccccc', width: '195px' }}>
            {/* <Link to={"user/" + item.key} style={{ cursor: 'pointer', textDecoration: 'none', color: 'black' }}> */}
                <img src={config.serverUrl + item.avatar} alt="profile_image" style={imgStyle} />
                <h3 style={{ margin: '2px 0 0 0' }}> {item.name} {item.surname} </h3>
            {/* </Link> */}
            {item.role.replace("-", " ")}
            { email } <br /><br />
            Joining Date: {item.joiningDate}
            <div style={{ marginTop: '10px', display: 'flex', flexWrap: 'wrap', width: '100%', justifyContent: 'center' }}>
                {socialsItems}
            </div>
        </div >

    )
}

const imgStyle = {
    borderRadius: '50%',
    border: '1px solid #bbbbbb',
    width: 'auto',
    maxWidth: '180px',
}

const socialsStyle = {
    margin: '0 4px',
    background: colors.color1,
    borderRadius: '50%',
    width: '40px',
    height: '40px',
    cursor: 'pointer',
    fontSize: '16pt',
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}

export default Item
