import React from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom'
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'

import {ContextProvider} from '@utils/Context'
import ProtectedRoute from '@common/ProtectedRoute'

import Header from '@common/Header'
import Footer from '@common/Footer'
import ScrollToTop from '@common/ScrollToTop'
import HomePage from '@pages/HomePage'
import Login from '@pages/LoginPage'
import Signup from '@pages/SignupPage'
import News from '@pages/News'
import Category from '@pages/Category'
import Dissemination from '@pages/Dissemination'
import Tags from '@pages/Tags'
import Projects from '@pages/Projects'
import Research from '@pages/Research'
import Node from '@pages/News/Node'
import NewNode from '@pages/News/NewNode'
import EditNode from '@pages/News/EditNode'
import User from '@pages/User'
import EditUser from '@pages/User/EditUser'
import Settings from '@pages/Settings'
import TeamPage from '@pages/TeamPage'
import Services from '@pages/Services'
import NotFound from '@pages/NotFound'
import Berlino from '@pages/Demos/Berlino'

function App() {
	return (
		<Router>
			<ContextProvider>
					<ReactNotification />
					<ScrollToTop>
						<div style={{display: 'flex', flexDirection: 'column', height:'100%', width: '100%'}}>
							<Header />
							<div style={{flexGrow: 1}}>
								<Switch>
									<Route exact path="/" render={ props => ( <HomePage /> ) } />
									<Route exact path="/login" render={ props => ( <Login /> ) } />
									<Route exact path="/signup" render={ props => ( <Signup /> ) } />
									<Route exact path="/news" render={ props => ( <News /> ) } />	
									<Route exact path="/research" render={ props => ( <Research /> ) } />
									<Route exact path="/projects" render={ props => ( <Projects /> ) } />
									<ProtectedRoute path="/news/newnode" render={<NewNode />} roles={["admin", "fcrlab"]} />
									<ProtectedRoute path="/news/manage" render={<NotFound />} roles={["admin", "fcrlab"]} />	
									<ProtectedRoute path="/news/edit/:id" render={<EditNode /> } roles={["admin", "fcrlab"]} />	
									<Route exact path="/news/:id" render={ props => ( <Node /> ) } />
                                                                        <Route exact path="/calls/:id" render={ props => ( <Node /> ) } />
                                                                        <Route exact path="/calls" render={ props => ( <Dissemination /> ) } />
                                                                        <Route exact path="/category/:id" render={ props => ( <Category /> ) } />
                                                                        <Route exact path="/tags/:id" render={ props => ( <Tags /> ) } />
 									<ProtectedRoute path="/user/edit/:id" render={<EditUser />} roles={["admin"]} />
									<Route exact path="/user/:id" render={ props => ( <User /> ) } />
									<Route exact path="/settings" render={ props => ( <Settings /> ) } />
									<Route exact path="/people" render={ props => ( <TeamPage /> ) } />
									<ProtectedRoute path="/services" render={<Services />} roles={["admin", "fcrlab", "collaborator"]} />
									<ProtectedRoute path="/demos/berlin" render={<Berlino />} roles={["admin", "fcrlab"]} />
									<Route path="*" exact={true} component={NotFound} />
								</Switch>
							</div>
							<Footer />
						</div>
					</ScrollToTop>
			</ContextProvider>
		</Router>
	);
}

export default App;
