import React, {Component} from 'react'
import CropImage from '@common/CropImage'
import Tags from '@common/Tags'

class Form extends Component {

    handleChange = (event) => {
        const {name, value, type, checked} = event.target
        type === "checkbox" ? this.props.updateState({ [name]: checked }) : this.props.updateState({ [name]: value })
    }

    updateTags = (tags) => {
        this.props.updateState( {tags} )
    }

    changeSlug = (e) => {
        if (e.target.value.match(/^[0-9a-zA-Z-]+$/) || e.target.value == ""){
             this.handleChange(e)
             if(this.props.state.autoSlug)
                 this.props.updateState({autoSlug: false})
        }
    }

    render() {
        let slideshow = this.props.state.slideshow,
            image = this.props.state.image,
            description = this.props.state.description,
            tags = this.props.state.tags,
            slug = this.props.state.slug,
            link = this.props.state.link,
            {shortTitle, date, place, category} = this.props.state;

        return (
            <div style={{padding: "3%", textAlign: 'left', display: "flex", flexDirection: "column"}}>
                <div style={{display: "flex", marginBottom: "30px"}} >
                    <input 
                        name="slideshow" 
                        checked={slideshow} 
                        type="checkbox" 
                        onChange={this.handleChange} 
                        style={{width: "30px", height: "30px", marginRight: "10px"}}
                    />
                    <label>Do you want to show this article in the homepage?</label>
                </div>

                {slideshow && (
                    <div>
                        <CropImage updateCropImage={this.props.updateState} aspect={2/1}/>
                        <br />
                    </div>
                )}

                {image !== undefined ? <div><img src={image} alt="newsImage" width="100%" /></div> : null}
                <br />

                <label>Slug*</label>
                <input type="text" name="slug" value={slug} onChange={this.changeSlug} required /> <br />

                <label>Description (<i>max 200 characters</i>)</label>
                <textarea name="description" value={description} onChange={this.handleChange} /> <br />

                <label>Link</label>
                <input type="text"  name="link" value={link} onChange={this.handleChange} /> <br />

                <label>Tags</label>
                <Tags tags={tags} updateTags={this.updateTags} />
                <br/>
                { category === "call" ? <div>
                    <label>Short Title*</label>
                    <input type="text" name="shortTitle" value={shortTitle} onChange={this.handleChange} required /> <br />

                    <label>Date</label>
                    <input type="text" name="date" value={date} onChange={this.handleChange} required /> <br />

                    <label>Place</label>
                    <input type="text" name="place" value={place} onChange={this.handleChange} required /> <br />
                </div>
                :
                null }
            </div>
        )
    }
}


export default Form
