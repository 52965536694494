import React, { Component } from 'react'
import {Link} from 'react-router-dom'
import Actions from '@common/Actions'
import Separator from '@common/Section/Separator'
import { ContextConsumer } from '@utils/Context'

class NewsItem extends Component {
    render() {
        let item = this.props.content
        let tags = item.tags ? item.tags.map((tag, index) => <Link to={"/tags/"+tag} key={index} style={tagStyle}>{ tag }</Link> ) : null
        let link = item.category === "call" ? `/calls/${item.slug}` : `/news/${item.slug}`;
        return (
            <div style={divStyle}>

                <div style={{flex: "6", minWidth: "200px"}}>
                    <Link to={"/category/"+item.category} style={{ ...linkStyle, color: "grey" }}>
                        [{ item.category }]{" "}
                    </Link>
                    <Link to={link} style={ linkStyle }>
                        { item.title }
                    </Link>
                    <p style={{textAlign: "justify"}}>{ item.description }</p>
                    <div style={{display: "flex", flexWrap: "wrap"}}> { tags } </div>
                </div>
                <Separator width="15px" height="10px" />
                <div style={{minWidth: "150px", margin: "10px 5px 0px 0px", flex: "1", padding: "5px"}}>
                    <ContextConsumer>
                        {
                            ({ isLogged, isAdmin, isFcrlab }) => (
                                isLogged && (isFcrlab || isAdmin) ? 
                                    <Actions content={[
                                        {link: link, title: "Read More"},
                                        {link: "/news/edit/"+item._id, title: "Edit"}
                                    ]} noLines={true} /> : 
                                    <Actions content={[
                                        {link: link, title: "Read More"}
                                    ]} noLines={true} />
                            )
                        }
                    </ContextConsumer>
                </div>
            </div>
        )
    }
}

const divStyle = {
    borderBottom: "1px solid #cccccc",
    textAlign: "left",
    padding: "20px",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "center"
}

const tagStyle = {
    padding: "5px",
    background: "#cccccc",
    marginRight: "5px",
    fontSize: "9pt",
    textDecoration: 'none',
    color: 'black'
}

const linkStyle = {
    fontSize: "14pt", 
    fontWeight: "bold", 
    textDecoration: "none",
    color: "black"
}

export default NewsItem
