import React, {Component} from 'react'

class NotFound extends Component {
    componentDidMount() {
        console.log("PageNotFound")
    }

    render() {
        return (
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <h1>Page under construction</h1>
            </div>
        ) 
    }
}

export default NotFound