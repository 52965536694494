import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import config from '@utils/config'
import CropImage from '@common/CropImage'
import SaveButton from '@common/SaveButton'
import { notify } from '@utils/notifications'
/* import DatePicker from 'react-datepicker'; */
import 'react-datepicker/dist/react-datepicker.css';

class UserInfo extends Component {
    state = {
        name: "",
        surname: "",
        title: "",
        researchId: "",
        avatar: "",
        dateOfBirth: new Date(),
        macAddress: "",
        birthplace: "",
        newFile: false,
        loading: false,
        status: 'noChanges'
    }

    updateImage = (newImage) => {
        let newFile = newImage.newfile,
            blob = newImage.blob;

        if (this.state.status !== 'unsaved')
            this.setState({ status: 'unsaved' });
        this.setState({ newFile, blob })
    }

    handleChange = (event) => {
        if (this.state.status !== 'unsaved')
            this.setState({ status: 'unsaved' });
        const { name, value, type, checked } = event.target
        type === "checkbox" ? this.setState({ [name]: checked }) : this.setState({ [name]: value })
    }

    changeDate = (key, date) => {
        this.setState({ [key]: date })
    }

    handleSubmit = (event, fun) => {
        let data = new FormData(),
            id = sessionStorage.getItem('id'),
            name = this.state.name,
            surname = this.state.surname,
            title = this.state.title,
            birthplace = this.state.birthplace,
            researchId = this.state.researchId,
            macAddress = this.state.macAddress,
            newFile = this.state.newFile;

        this.setState({ status: 'loading' });
        event.preventDefault();
        console.log(macAddress)
        data.append('name', name || "");
        data.append('surname', surname || "");
        data.append('title', title || "");
        data.append('birthPlace', birthplace || "");
        data.append('researchId', researchId || "");
        data.append('macAddress', macAddress || "");
        //data.append('dateOfBirth', this.state.dateOfBirth.toLocaleString("it-IT", { dateStyle: 'short', year: "numeric" }));

        if (newFile)
            data.append('image', this.state.blob)
        fetch(config.serverUrl + '/users/updateUser/' + id, {
            method: 'PATCH',
            headers: { 'access-token': sessionStorage.getItem('access_token') },
            body: data
        })
            .then(response => response.json())
            .then((res) => {
                if (res.status === 200) {
                    notify("Success!", res.body, "success", "top-center");
                    this.setState({ status: 'saved' });
                }
                else {
                    this.setState({ status: 'error' });
                    notify("Error!", res.body, "warning", "top-center");
                }
            })
            .catch(error => {
                this.setState({ status: 'error' });
                notify("Error", JSON.stringify(error), "warning", "top-center")
            })
    }

    refleshInfo = () => {
        let url = config.serverUrl + "/users/getUserInfo/" + sessionStorage.getItem('id') + "?fields=name,surname,title,researchId,avatar,dateOfBirth,birthPlace,macAddress";
        this.setState({ loading: true });
        fetch(url)
            .then(response => response.json())
            .then(data => {
                let dateOfBirth = data.dateOfBirth ? data.dateOfBirth.split("/") : ['01', '01', '1970'];
                this.setState({
                    name: data.name || "",
                    surname: data.surname || "",
                    dateOfBirth: new Date(dateOfBirth[2], dateOfBirth[1] - 1, dateOfBirth[0]) || "",
                    birthplace: data.birthPlace || "",
                    title: data.title || "",
                    researchId: data.researchId || "",
                    macAddress: data.macAddress || "",
                    avatar: data.avatar || "",
                    loading: false,
                    newFile: false
                })
            })
    }

    componentDidMount() {
        this.refleshInfo();
    }

    render() {
        return (
            <div style={{ background: 'white', height: '100%', marginLeft: '5px' }}>
                <form style={formStyle} onSubmit={this.handleSubmit}>
                    <h1>Edit your user info</h1>
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        <div>
                            <div style={{ border: '2px solid #ccc', borderRadius: '10px', marginTop: '10px', padding: '10px', marginRight: '30px' }}>
                                <center><h4>Personal Info</h4></center><hr /><br />
                                <label>Name and Surname</label>
                                <input
                                    type="text"
                                    name="name"
                                    placeholder="Your name"
                                    onChange={this.handleChange}
                                    value={this.state.name}
                                    required
                                />
                                <input
                                    type="text"
                                    name="surname"
                                    placeholder="Your surname"
                                    onChange={this.handleChange}
                                    value={this.state.surname}
                                    required
                                />
{/*                                 <br /><br /><label>Date of birth</label><br />
                                <DatePicker
                                    name="dateOfBirth"
                                    placeholderText="Date of birth"
                                    selected={this.state.dateOfBirth}
                                    onChange={date => this.changeDate('dateOfBirth', date)}
                                    customInput={<input type="text" />}
                                    dateFormat="dd/MM/yyyy"
                                /> */}
                                <br /><br /><label>Birth place</label>
                                <input
                                    type="text"
                                    name="birthplace"
                                    placeholder="Your birth place"
                                    onChange={this.handleChange}
                                    value={this.state.birthplace}
                                />
                            </div>
                            <div style={{ border: '2px solid #ccc', borderRadius: '10px', marginTop: '10px', padding: '10px', marginRight: '30px' }}>
                                <h4>Title</h4>
                                <select name="title" value={this.state.title} onChange={this.handleChange}>
                                    <option value="none">{"<None>"}</option>
                                    <option value="Full Professor">Full Professor</option>
                                    <option value="Associate Professor">Associate Professor</option>
                                    <option value="Researcher">Researcher</option>
                                    <option value="Research Fellow">Research Fellow</option>
                                    <option value="PHD Student">PHD Student</option>
                                    <option value="Scholarship Recipient">Scholarship Recipient</option>
                                    <option value="Master's Degree Student">Master's Degree Student</option>
                                    <option value="Student">Student</option>
                                    <option value="Senior Developer">Senior Developer</option>
                                    <option value="Developer">Developer</option>
                                </select>
                                <h4>Research ID</h4>
                                <input
                                    type="text"
                                    name="researchId"
                                    placeholder="Your research ID"
                                    onChange={this.handleChange}
                                    value={this.state.researchId}
                                />
                                <h4>Device MAC Address</h4>
                                <input
                                    type="text"
                                    name="macAddress"
                                    placeholder="Your device MAC address"
                                    onChange={this.handleChange}
                                    value={this.state.macAddress}
                                />
                            </div>
                        </div>
                        <div style={{ border: '2px solid #ccc', borderRadius: '10px', marginTop: '10px', padding: '10px', marginRight: '30px' }}>
                            <div style={{ maxWidth: '250px' }}>
                                <h4>Avatar</h4>
                                <CropImage updateCropImage={this.updateImage} aspect={1 / 1} />
                                {this.state.newFile ? null : <img src={config.serverUrl + this.state.avatar} alt={this.state.avatar} width="250px" />}
                            </div>
                        </div>
                    </div>
                    <SaveButton status={this.state.status} />
                </form>
            </div >
        )
    }
}

const formStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    width: '100%%',
    minWidth: '280px',
    maxWidth: '1000px',
    padding: '40px 5% 40px 5%',
}


export default withRouter(UserInfo)
