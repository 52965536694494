import React, { Component } from 'react'
import { TwitterTimelineEmbed } from 'react-twitter-embed';
import colors from '@utils/colors';


class Socials extends Component {

    render(){
        return (
            <div style={{background: colors.color6, padding: "0"}}>

                <div className="fb-page" style={{margin: '0'}}
                    data-href="https://www.facebook.com/fcrlab/" 
                    data-tabs="timeline" 
                    data-width="300"
                    data-height="350" 
                    data-small-header="false" 
                    data-adapt-container-width="true" 
                    data-hide-cover="true" 
                    data-show-facepile="true">
                    <blockquote cite="https://www.facebook.com/fcrlab/" 
                        className="fb-xfbml-parse-ignore">
                        <a href="https://www.facebook.com/fcrlab/">FCRlab Unime</a>
                    </blockquote>
                </div>
    
                <div style={{height: '30px'}}></div>
                <TwitterTimelineEmbed
                    sourceType="profile"
                    screenName="fcrlabunime"
                    options={{height: 350, width: "300px"}}
                />
    
            </div>
        )
    }
    componentDidMount(){
        try {
            window.FB.XFBML.parse();
        }
        catch(err)
        {

        }
        
     }
}

export default Socials