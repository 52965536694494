import React, { Component } from 'react'
import { ContextConsumer } from '@utils/Context'
import Item from './Item'
import Dropdown from './Dropdown'
import './style.css'

class Navbar extends Component {
    state = {
        navClass: "topnav",
        lastPath: this.props.location
    }

    myFunction = () => {
        if (this.state.navClass === "topnav") {
            this.setState({ navClass: "topnav responsive" });
        } else {
            this.setState({ navClass: "topnav" });
        }
    }

    render() {
        let lastPath = this.state.lastPath
        let location = this.props.location
        if (lastPath !== location) {
            // eslint-disable-next-line
            this.state.navClass = "topnav"
            // eslint-disable-next-line
            this.state.lastPath = location
        }
        return (
            <div className={this.state.navClass}>
                <Item link="/" title="Home" />
                <Item link="/people" title="People" />
                <a target="_blank" href="https://www.scopus.com/authid/detail.uri?authorId=12645423500">Publications</a>
                <Item link="/calls" title="Dissemination" />
                <Item link="/news" title="News" />
                {/*                 <Dropdown 
                    title="Gallery"
                    items={[
                        <Item key="0" link="/images" title="Images" />,
                        <Item key="1" link="/videos" title="Videos" />
                    ]}
                /> */}
                <ContextConsumer>
                    {
                        ({ isLogged, isAdmin }) => (
                            isLogged && !isAdmin ?
                                <div>
                                    <Dropdown
                                        title="Demos"
                                        items={[
                                            <Item key="0" link="/demos/berlin" title="Berlin" />,
                                        ]}
                                    />
                                    <Dropdown
                                        title="Utilities"
                                        items={[
                                            <a key="0" href="http://projmanage.unime.it/">OpenProject</a>,
                                            <a key="1" href="https://nc.me.pi2s2.it/nextcloud">NextCloud</a>,
                                            <a key="2" href="http://fiware-wilma.unime.it">Forum</a>,
                                            <Item key="3" link="/services" title="Services" />,
                                        ]}
                                    />
                                </div>
                                : null
                        )
                    }
                </ContextConsumer>
                 <ContextConsumer>
                    {
                        ({ isLogged, isAdmin }) => (
                            isLogged && isAdmin ?
                                <div>
                                    <Dropdown
                                        title="Demos"
                                        items={[
                                            <Item key="0" link="/demos/berlin" title="Berlin" />,
                                        ]}
                                    />
                                    <Dropdown
                                        title="Utilities"
                                        items={[
                                            <a key="0" href="http://projmanage.unime.it/">OpenProject</a>,
                                            <a key="1" href="https://nc.me.pi2s2.it/nextcloud">NextCloud</a>,
                                            <a key="2" href="http://fiware-wilma.unime.it">Forum</a>,
                                            <Item key="3" link="/services" title="Services" />,
                                            <a key="4" href="https://www.histats.com/viewstats/?act=2&sid=4537044">Stats</a>
                                        ]}
                                    />
                                </div>
                                : null
                        )
                    }
                </ContextConsumer>
                {/* Menu Responsive Icon Button */}
                {
                    // eslint-disable-next-line
                }<a style={{ fontSize: "15px" }} className="icon" onClick={this.myFunction}>
                    <i className={'icon-bar'} />
                    <i className={'icon-bar'} />
                    <i className={'icon-bar'} />
                </a>
            </div>
        )
    }

}

export default Navbar
