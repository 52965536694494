import React from 'react'
import Collaborations from './Collaborations'
import ContactUs from './ContactUs'
import Copyright from './Copyright'

function Footer() {
    return (
        <div>
            <Collaborations />
            <ContactUs />
            <Copyright />
        </div>
    )
}

export default Footer