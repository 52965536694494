import React from 'react'

function Separator(props) {
    let sepStyle = {}
    let hideOnMobile = props.hideOnMobile ? "hideOnMobile" : null
    if(props.width) sepStyle['width']=props.width
    if(props.height) sepStyle['height']=props.height
    return (
        <div style={sepStyle} className={hideOnMobile} />
    )
}

export default Separator