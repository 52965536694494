import React from 'react'
import loading from './loading.svg'

function Loading(props) {
    let width = props.width ? props.width : '100%',
        height = props.height ? props.height : '100%'
    return (
        <div style={{width, height, display: 'flex', justifyContent: 'center', alignItems: 'center'}} >
            <img src={loading} alt="Loading..." style={{width: '30%', height: '30%'}} />
        </div>   
    )
}

export default Loading;