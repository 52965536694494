import React, { Component } from 'react'
import config from '@utils/config'
import SaveButton from '@common/SaveButton'
import { notify } from '@utils/notifications'
import SortableItem from './Item'
import Autocomplete from 'react-autocomplete';
import 'react-datepicker/dist/react-datepicker.css';



class Team extends Component {
    state = {
        email: "",
        items: [],
        team: [],
        status: 'noChanges'
    }

    changeDate = (date, i) => {
        let team = this.state.team;
        team[i].joiningDate = date.toLocaleString("it-IT", { month: '2-digit', year: "numeric" });
        this.setState({ team, status: 'unsaved' })
    }

    changeRole = (e, i) => {
        let team = this.state.team;
        team[i].role = e.target.value;
        this.setState({ team, status: 'unsaved' })
    }

    removeItem = (id) => {
        let team = this.state.team;
        delete team[id];
        this.setState({ team, status: 'unsaved' })
    }

    onSortItems = (items) => {
        this.setState({ items: items, status: 'unsaved' });
    }

    onChange = (e) => {
        let email = e.target.value,
            url = config.serverUrl + "/users/searchUser/" + email + "?limit=5";

        this.setState({ email })
        if (email) {
            fetch(url, {
                method: 'GET',
                headers: { 'access-token': sessionStorage.getItem('access_token') }
            })
                .then(response => response.json())
                .then(data => {
                    if (data) {
                        let items = data.map(item => ({ key: item._id, email: item.email })),
                            team = this.state.team.map(item => (item.email)),
                            difference = items.filter(x => !team.includes(x.email));
                        this.setState({ items: difference, })
                    }
                })
        }
    }

    onSelect = (email) => {
        let team = this.state.team,
            items = this.state.items,
            user = items.filter(x => x.email === email);
        user[0].joiningDate = new Date().toLocaleString("it-IT", { month: '2-digit', year: "numeric" });
        user[0].role = 'Developer';
        team.push(user[0]);

        this.setState({ team, email: '', status: 'unsaved', items: [] })
    }

    componentDidMount() {
        let url = config.serverUrl + "/team/FCRLab?fields=email,joiningDate,role";
        this.setState({ loading: true });
        fetch(url)
            .then(response => response.json())
            .then(data => {
                data.forEach(x => {
                    x.key = x._id
                })
                this.setState({ team: data, loading: false, })
            })
    }

    handleSubmit = (event) => {
        let team = this.state.team,
            data = team.map(x => ({ id: x.key, role: x.role, joiningDate: x.joiningDate }))

        event.preventDefault();
        console.log(data)
         fetch(config.serverUrl + '/team/FCRLab', {
            method: 'POST',
            headers: { 'access-token': sessionStorage.getItem('access_token'),
            'Content-Type': 'application/json' },
            body: JSON.stringify({data: data})
        })
            .then(response => response.json())
            .then((res) => {
                console.log("RES:", res)
                if (res.status === 200) {
                    notify("Success!", res.body, "success", "top-center");
                    this.setState({ status: 'saved' });
                }
                else {
                    this.setState({ status: 'error' });
                    notify("Error!", res.body, "warning", "top-center");
                }
            })
            .catch(error => {
                this.setState({ status: 'error' });
                notify("Error", JSON.stringify(error), "warning", "top-center")
            })
    }

    render() {
        let items = this.state.email ? this.state.items : [],
            email = this.state.email,
            team = this.state.team

        var listItems = team.map((item, i) =>
            <SortableItem
                key={i}
                onSortItems={this.onSortItems}
                items={team}
                sortId={i}
                userId={i}
                changeRole={this.changeRole}
                changeDate={this.changeDate}
                removeItem={this.removeItem}
            >
                {item}
            </SortableItem>
        );
        return (
            <div style={{ background: 'white', height: '100%', marginLeft: '5px' }}>
                <form style={formStyle} onSubmit={this.handleSubmit}>
                    <h1>Team</h1>
                    <h3>Add a new member</h3>
                    <Autocomplete
                        getItemValue={(item) => item.email}
                        items={items}
                        renderItem={(item, isHighlighted) =>
                            <div key={item.key} style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
                                {item.email}
                            </div>
                        }
                        value={email}
                        onChange={this.onChange}
                        onSelect={(val) => this.onSelect(val)}
                    />

                    <h3 style={{ marginBottom: '0' }}>Team</h3>
                    <ul className='sortable-list'>
                        {listItems}
                    </ul>
                    <SaveButton status={this.state.status} />
                </form>
            </div>
        )
    }
}

const formStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    alignItems: 'left',
    width: '100%%',
    minWidth: '200px',
    maxWidth: '1000px',
    padding: '40px 5% 40px 5%',
}




export default Team