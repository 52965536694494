import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Form from './Form'
import Loading from '@common/Loading'
import './style.css'

class Logs extends Component {
    constructor() {
        super();
        this.form = React.createRef();
    }

    state = {
        logs: [],
        page: 1,
        limit: 30,
        loading: false
    }

    setLogs = (logs) => {
        this.setState({ logs, loading: false })
        window.scrollTo(0, 0);
    }

    nextPage = () => {
        let page = this.state.page
        this.setState({ page: page + 1, loading: true })
        this.form.current.getLogs();
    }

    previousPage = () => {
        let page = this.state.page
        if (page > 1) {
            this.setState({ page: page - 1, loading: true })
            this.form.current.getLogs();
        }
    }

    setLoading = () => {
        this.setState({ loading: true, page: 1 })
    }

    render() {
        let logs = this.state.logs,
            page = this.state.page,
            rows = logs.map(log => {
                let resource = log.resource.length > 60 ? log.resource.substring(0, 60) + "..." : log.resource;
                return (
                    <tr key={log._id}>
                        <td data-label="Method">{log.method}</td>
                        <td data-label="Status">{log.status}</td>
                        <td data-label="Resource" title={log.resource}>{resource}</td>
                        <td data-label="Ip Address">{log.ip}</td>
                        <td data-label="User"><Link to={"user/" + log.user}>{log.user}</Link></td>
                        <td data-label="Role">{log.role}</td>
                        <td data-label="Timestamp">{new Date(log.timestamp).toLocaleString("it-IT", { dateStyle: 'short', timeStyle: 'medium' })}</td>
                    </tr>
                )
            })
        return (
            <div style={{ background: 'white', height: '100%', marginLeft: '5px', padding: '40px 5% 40px 5%' }}>
                <h1>Logs</h1>
                <Form
                    setLogs={this.setLogs}
                    setLoading={this.setLoading}
                    limit={this.state.limit}
                    page={page}
                    ref={this.form}
                />

                {this.state.loading ?
                    <Loading height='50%' /> :
                    logs.length > 0 ?
                        <div>
                            <table>
                                <thead>
                                    <tr style={{userSelect: 'none'}}>
                                        <th scope="col" style={{ width: '70px' }}>Method</th>
                                        <th scope="col" style={{ width: '70px' }}>Status</th>
                                        <th scope="col">Resource</th>
                                        <th scope="col" style={{ width: '170px' }}>Ip Address</th>
                                        <th scope="col" style={{ width: '200px' }}>User</th>
                                        <th scope="col" style={{ width: '80px' }}>Role</th>
                                        <th scope="col" style={{ width: '120px' }}>Timestamp</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {rows}
                                </tbody>
                            </table>
                            <center style={{ marginTop: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                {page > 1 ?
                                    <i className="fa fa-arrow-left fa-2x" style={{ cursor: 'pointer' }} onClick={this.previousPage} />
                                    : null
                                }
                                <h1 style={{ margin: '0 20px' }}>Page {page}</h1>
                                <i className="fa fa-arrow-right fa-2x" style={{ cursor: 'pointer' }} onClick={this.nextPage} />
                            </center>
                        </div>
                        : null
                }
            </div>
        )
    }
}

export default Logs