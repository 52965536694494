import React from 'react'
import {Link} from 'react-router-dom'
import colors from '@utils/colors'

function Actions(props) {
    let btns = props.content.map( (item, index) => <Link key={index} to={item.link}><button style={btnStyle}>{item.title}</button></Link> ),
        hr = props.noLines ? false : true
    return (
        <div>
            {hr ? <hr /> : null }
                <div style={{justifyContent: "left", display: "flex", flexWrap: "wrap"}}>
                    {btns}
                </div>
            {hr ? <hr /> : null }
        </div>
    )
}

const btnStyle = {
    width: "100px",
    height: "35px",
    margin: "1px 10px 1px 0",
    background: colors.color2,
    border: "none",
    borderRadius: "2px",
    color: colors.color5,
    fontWeight: "bold",
    cursor: "pointer",
    outline: "none"
}

export default Actions