import React from 'react'
import colors from '@utils/colors'

function Item(props) {
    let fontWeight = props.selected ? 'bold' : 'normal'
    return (
        <div onClick={() => props.handleClick(props.id)} style={{...itemStyle, fontWeight}}><i style={{marginRight: '10px'}} className={"fa fa-"+props.icon} aria-hidden="true"></i>{props.title}</div>
    )
}

const itemStyle = {
    width: '100%',
    padding: '15px 10px',
    display: 'flex',
    alignItems: 'center',
    border: '1px solid ' + colors.color5,
    cursor: 'pointer'
}

export default Item