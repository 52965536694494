import React, { Component } from 'react'
import colors from '@utils/colors'
import config from '@utils/config'
import Loading from '@common/Loading'
import Modal from 'react-responsive-modal';
import { notify } from '@utils/notifications'

class BulkSign extends Component {
    state = {
        selectedSign: 0,
        n_document_signed: 0,
        signatures: [],
        open: false,
        loading: true,
        passphrase: ''
    }

    viewDocument = (uri) => {
        let link = config.serverUrl + "/api/services/document?path=" + uri
        window.open(link, "_blank")
    }

    signDocument = () => {
        this.setState({ open: true, passphrase: '' })
        let url = config.serverUrl + "/api/services/signatures/" + sessionStorage.getItem('id');
        fetch(url, { headers: { 'access-token': sessionStorage.getItem('access_token') } })
            .then(response => response.json())
            .then(data => {
                if (data)
                    this.setState({ signatures: data, loading: false })
            })
    }

    bulkSignChain = (i, totalSign) => {
        let promise = new Promise((resolve, reject) => {
            let item = this.props.items[i]
            this.sign(item).then(() => resolve() )
        })
        console.log("Sign ", i)
        if(i < totalSign)
            return promise.then(() => this.bulkSignChain(++i, totalSign))
        else
            return promise
    }

    bulkSign = () => {
        let items = this.props.items,
            totalSign = items.length

        this.bulkSignChain(0, totalSign-1)
            .then(() => {
                let { n_document_signed } = this.state
                if (n_document_signed === totalSign)
                    notify("Success!", "Document signed successfully", "success", "top-center");
                else if (n_document_signed === 0)
                    notify("Error", "None of your documents has been signed", "warning", "top-center");
                else
                    notify("Error!", "Only " + n_document_signed + "/" + totalSign + " documents has been signed.", "warning", "top-center");
                this.setState({ open: false, loading: false, passphrase: "", n_document_signed: 0 });
                this.props.refleshList()
            })
            .catch(() => console.log("Error"))
    }

    sign = (item) => {
        return new Promise((resolve, reject) => {
            let id = sessionStorage.getItem('id'),
                { passphrase, signatures, selectedSign } = this.state,
                { lineHeight, version } = signatures[selectedSign],
                { _id, unsignedDocument, signedDocument, requiredSignatures, nextSignature, orientation, searchSign, page } = item,
                inputPDF, outputPDF,
                data = new FormData(),
                url = config.serverUrl + "/api/services/sign/" + _id + "/" + id + "/" + version;

            inputPDF = nextSignature === 0 ? unsignedDocument : signedDocument
            for (let i = 0; i < nextSignature; i++)
                inputPDF += "_" + i

            outputPDF = signedDocument
            for (let i = 0; i < nextSignature; i++)
                outputPDF += "_" + i

            data.append("inputPDF", inputPDF + "." + item.format)
            data.append("outputPDF", outputPDF + "_" + nextSignature + '.' + item.format)
            data.append("passphrase", passphrase)
            data.append("lineHeight", lineHeight)
            data.append("signs", JSON.stringify([requiredSignatures[nextSignature].position]))
            data.append("orientation", orientation)
            data.append("searchSign", searchSign)
            data.append("page", page)

            this.setState({ loading: true })
            fetch(url, {
                method: 'POST',
                headers: { 'access-token': sessionStorage.getItem('access_token') },
                body: data
            })
                .then(response => response.json())
                .then((res) => {
                    console.log(res)
                    if (res.status === 200) {
                        let n_signed = this.state.n_document_signed
                        this.setState({ n_document_signed: ++n_signed })
                        resolve()
                    }
                    resolve()

                })
                .catch(error => {
                    resolve()
                })

        })
    }

    componentDidMount() {
        this.setState({ items: this.props.items })
    }

    onCloseModal = () => {
        this.setState({ open: false, passphrase: "", selectedSign: 0 });
    };

    handleChange = (event) => {
        if (this.state.status !== 'unsaved')
            this.setState({ status: 'unsaved' });
        const { name, value, type, checked } = event.target
        type === "checkbox" ? this.setState({ [name]: checked }) : this.setState({ [name]: value })
    }

    render() {
        let { open, signatures, selectedSign, loading, n_document_signed } = this.state,
            mySignatures = signatures.map((item, i) => <option value={i} key={i}>Signature {i}</option>)

        return (
            <div>
                <div style={{ marginLeft: '10px' }}>
                    <button style={btnStyle} onClick={this.signDocument}>Bulk sign documents</button>
                </div>

                <Modal open={open} onClose={this.onCloseModal} center>
                    {loading ? <div><Loading /><center>{n_document_signed}{"/" + this.props.items.length + " signed"}</center></div> :
                        mySignatures.length > 0 ?
                            <div>
                                <h2>Signing</h2>
                                Select a signature
                                <select name="selectedSign" value={selectedSign} onChange={this.handleChange} >
                                    {mySignatures}
                                </select>
                                Passphrase:
                                <input type='password' name="passphrase" onChange={this.handleChange} />
                                <button style={btnStyle} onClick={this.bulkSign}>Sign document</button>
                            </div> :
                            <div>
                                <h2>Signing</h2>
                                <div>You have to define a signature in your profile settings</div>
                            </div>
                    }
                </Modal>
            </div>
        )
    }
}

const btnStyle = {
    minWidth: '150px',
    maxWidth: '250px',
    height: "27px",
    background: colors.color2,
    border: "none",
    borderRadius: "5px",
    color: colors.color5,
    fontWeight: "bold",
    cursor: "pointer",
    marginRight: '20px'
}

export default BulkSign
