import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import config from '@utils/config'
import SaveButton from '@common/SaveButton'
import { notify } from '@utils/notifications'
import CurrencyInput from 'react-currency-input';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const titleToITA = {
    "Full Professor": "PROFESSORE ORDINARIO",
    "Associate Professor": "PROFESSORE ASSOCIATO",
    "Researcher": "RICERCATORE",
    "Research Fellow": "RESEARCH FELLOW",
    "PHD Student": "DOTTORANDO",
    "Scholarship Recipient": "BORSISTA",
    "Master's Degree Student": "STUDENTE MAGISTRALE",
    "Student": "STUDENTE",
    "Senior Developer": "SVILUPPATORE SENIOR",
    "Developer": "SVILUPPATORE"
}

const transportToITA = {
    "plane": "AEREO",
    "train": "TRENO",
    "bus": "AUTOBUS",
    "car": "AUTO",
    "motorcycle": "MOTOCICLETTA"
}

class MissionRequest extends Component {
    state = {
        title: "",
        name: "",
        surname: "",
        dateOfBirth: null,
        birthPlace: "",

        place: "",
        startDate: new Date(),
        endDate: new Date(),
        reason: "",

        refund: false,
        departmentFunds: "true",
        fundsTitle: "",
        refundAmount: "",
        fundsOwner: true,
        fundsOwnerName: "",

        meansOfTransport: "",
        transportOwner: false,
        model: "",
        licensePlace: "",
        insuranceCompany: "",
        insuranceNumber: "",
        startInsurance: null,
        endInsurance: null
    }

    handleChange = (event) => {
        if (this.state.status !== 'unsaved')
            this.setState({ status: 'unsaved' });
        const { name, value, type, checked } = event.target
        type === "checkbox" ? this.setState({ [name]: checked }) : this.setState({ [name]: value })
    }

    handleMoney = (event, maskedvalue, floatvalue) => {
        this.setState({ refundAmount: floatvalue });
    }

    changeDate = (key, date) => {
        this.setState({ [key]: date })
    }

    handleSubmit = (event, fun) => {
        let formData = new FormData(),
            startDate = this.state.startDate,
            endDate = this.state.endDate,
            departmentFunds = this.state.departmentFunds,
            transportOwner = this.state.transportOwner;

        event.preventDefault();
        formData.append('title', titleToITA[this.state.title]);
        formData.append('name', this.state.name);
        formData.append('surname', this.state.surname);
        formData.append('dateOfBirth', this.state.dateOfBirth.toLocaleString("it-CH", { dateStyle: 'short' }));
        formData.append('birthPlace', this.state.birthPlace);
        formData.append('place', this.state.place);
        formData.append('startDate', startDate.toLocaleString("it-IT", { dateStyle: 'long' }));
        formData.append('startHour', startDate.toLocaleString("it-IT", { timeStyle: 'short' }));
        formData.append('endDate', endDate.toLocaleString("it-IT", { dateStyle: 'long' }));
        formData.append('endHour', endDate.toLocaleString("it-IT", { timeStyle: 'short' }));
        formData.append('reason', this.state.reason);
        formData.append('refund', this.state.refund);
        formData.append('departmentFunds', departmentFunds);
        formData.append('fundsTitle', departmentFunds === 'true' ? this.state.fundsTitle : "");
        formData.append('refundAmount', departmentFunds === 'true' ? this.state.refundAmount : "");
        formData.append('fundsOwner', departmentFunds === 'true' ? this.state.fundsOwner : "");
        departmentFunds === 'true' ?
            formData.append('fundsOwnerName', !this.state.fundsOwner ? this.state.fundsOwnerName : "il sottoscritto") :
            formData.append('fundsOwnerName', "");
        formData.append('meansOfTransport', transportToITA[this.state.meansOfTransport]);
        formData.append('transportOwner', transportOwner);
        formData.append('model', transportOwner ? this.state.model : "");
        formData.append('licensePlate', transportOwner ? this.state.licensePlate : "");
        formData.append('insuranceCompany', transportOwner ? this.state.insuranceCompany : "");
        formData.append('insuranceNumber', transportOwner ? this.state.insuranceNumber : "");
        formData.append('startInsurance', transportOwner ? this.state.startInsurance.toLocaleString("it-IT", { dateStyle: 'long' }) : "");
        formData.append('endInsurance', transportOwner ? this.state.endInsurance.toLocaleString("it-IT", { dateStyle: 'long' }) : "");

        if(transportOwner && this.state.endInsurance < endDate){
            notify("Warning!", "Attention! Your vehicle insurance will be expired.", "warning", "top-center");
            return;
        }

        this.setState({ status: 'loading' });

        fetch(config.serverUrl + '/api/services/build/missionRequest', {
            method: 'POST',
            body: formData
        })
            .then(response => response.json())
            .then((res) => {
                if (res.status === 200) {
                    notify("Success!", "Mission request builded", "success", "top-center");
                    this.setState({ status: 'saved', link: res.body });
                }
                else {
                    this.setState({ status: undefined });
                    notify("Warning!", res.body, "warning", "top-center");
                }
            })
            .catch(error => {
                this.setState({ status: 'error' });
                notify("Error", JSON.stringify(error), "warning", "top-center")
            })
    }

    componentDidMount() {
        let url = config.serverUrl + "/users/getUserInfo/" + sessionStorage.getItem('id') + "?fields=title,name,surname,dateOfBirth,birthPlace,vehicles";
        this.setState({ loading: true });
        fetch(url)
            .then(response => response.json())
            .then(data => {
                console.log(data)
                if (data) {
                    let dateOfBirth = data.dateOfBirth ? data.dateOfBirth.split("/") : "";

                    dateOfBirth = dateOfBirth ? new Date(dateOfBirth[2], dateOfBirth[1] - 1, dateOfBirth[0]) : ""
                    this.setState({
                        title: data.title || "",
                        name: data.name || "",
                        surname: data.surname || "",
                        dateOfBirth: dateOfBirth,
                        birthPlace: data.birthPlace || "",
                        loading: false,
                    })
                    if (data.vehicles) {
                        let vehicle = data.vehicles[0];
                        let startInsurance = vehicle.startInsurance ? vehicle.startInsurance.split("/") : ['01', '01', '2019'],
                            endInsurance = vehicle.endInsurance ? vehicle.endInsurance.split("/") : ['01', '01', '2019']
                        startInsurance = startInsurance ? new Date(startInsurance[2], startInsurance[1] - 1, startInsurance[0]) : ""
                        endInsurance = endInsurance ? new Date(endInsurance[2], endInsurance[1] - 1, endInsurance[0]) : ""
                        if (vehicle)
                            this.setState({
                                meansOfTransport: vehicle.type || "",
                                model: vehicle.licensePlate || "",
                                licensePlate: vehicle.licensePlate || "",
                                insuranceCompany: vehicle.insuranceCompany || "",
                                insuranceNumber: vehicle.insuranceNumber || "",
                                startInsurance: startInsurance,
                                endInsurance: endInsurance,
                            })
                    }
                    else
                        this.setState({ loading: false })
                }
                else
                    this.setState({ loading: false })
            })
    }

    render() {
        let refund = this.state.refund,
            departmentFunds = this.state.departmentFunds,
            fundsOwner = this.state.fundsOwner,
            transportOwner = this.state.transportOwner,
            link = this.state.link;
        return (
            <div style={{ background: 'white', height: '100%', marginLeft: '5px' }}>
                <form style={formStyle} onSubmit={this.handleSubmit}>
                    <center><h1>Mission Request</h1></center>
                    <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%', justifyContent: 'space-evenly' }}>
                        <div style={{ width: "48%", minWidth: '250px' }}>
                            <div style={{ border: '2px solid #ccc', borderRadius: '10px', marginTop: '10px', padding: '10px' }}>
                                <center><h4>Personal Info</h4></center><hr /><br />
                                <div>
                                    <label>Title</label>
                                    <select name="title" value={this.state.title} onChange={this.handleChange}>
                                        <option value="Full Professor">Full Professor</option>
                                        <option value="Associate Professor">Associate Professor</option>
                                        <option value="Researcher">Researcher</option>
                                        <option value="Research Fellow">Research Fellow</option>
                                        <option value="PHD Student">PHD Student</option>
                                        <option value="Scholarship Recipient">Scholarship Recipient</option>
                                        <option value="Master's Degree Student">Master's Degree Student</option>
                                        <option value="Student">Student</option>
                                        <option value="Senior Developer">Senior Developer</option>
                                        <option value="Developer">Developer</option>
                                    </select>
                                    <br /><br />
                                    <label>Name</label>
                                    <input
                                        type="text"
                                        name="name"
                                        placeholder="Your name"
                                        onChange={this.handleChange}
                                        value={this.state.name}
                                        required
                                    />
                                    <br /><br />
                                    <label>Surname</label>
                                    <input
                                        type="text"
                                        name="surname"
                                        placeholder="Your surname"
                                        onChange={this.handleChange}
                                        value={this.state.surname}
                                        required
                                    />
                                    <br /><br />
                                    <label>Birth place</label>
                                    <input
                                        type="text"
                                        name="birthPlace"
                                        placeholder="Your birth place"
                                        onChange={this.handleChange}
                                        value={this.state.birthPlace}
                                        required
                                    />
                                    <br /><br />
                                    <label>Date of birth</label><br />
                                    <DatePicker
                                        name="dateOfBirth"
                                        placeholderText="Date of birth"
                                        selected={this.state.dateOfBirth}
                                        onChange={date => this.changeDate('dateOfBirth', date)}
                                        customInput={<input type="text" />}
                                        dateFormat="dd/MM/yyyy"
                                    />
                                </div>
                            </div>

                            <div style={{ border: '2px solid #ccc', borderRadius: '10px', marginTop: '10px', padding: '10px' }}>
                                <center><h4>Mission Info</h4></center><hr /><br />
                                <div>
                                    <label>Place of the mission</label>
                                    <input
                                        type="text"
                                        name="place"
                                        placeholder="Place of the mission"
                                        onChange={this.handleChange}
                                        value={this.state.place}
                                        required
                                    />

                                    <br /><br />
                                    <label>Date start - Date end</label>
                                    <div style={{ display: 'flex' }} >
                                        <DatePicker
                                            name="startDate"
                                            placeholderText="Date of start"
                                            selected={this.state.startDate}
                                            onChange={date => this.changeDate('startDate', date)}
                                            customInput={<input type="text" />}
                                            showTimeSelect
                                            dateFormat="dd/MM/yyyy hh:mm"
                                        />
                                        <DatePicker
                                            name="endDate"
                                            placeholderText="Date of end"
                                            selected={this.state.endDate}
                                            onChange={date => this.changeDate('endDate', date)}
                                            customInput={<input type="text" />}
                                            showTimeSelect
                                            dateFormat="dd/MM/yyyy hh:mm"
                                        />
                                    </div>
                                    <br /><br />
                                    <label>Reason</label>
                                    <textarea name="reason" value={this.state.reason} onChange={this.handleChange} placeholder="Reason of the mission" required />
                                </div>
                            </div>

                        </div>
                        <div style={{ width: "48%", minWidth: '250px' }}>

                            <div style={{ border: '2px solid #ccc', borderRadius: '10px', marginTop: '10px', padding: '10px' }}>
                                <center><h4 style={{ maxWidth: '385px' }}>Refund</h4></center><hr /><br />
                                <div>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <input
                                            name="refund"
                                            type="checkbox"
                                            checked={this.state.refund}
                                            onChange={this.handleChange}
                                            style={{ width: "25px", height: "25px" }}
                                        /> I want to ask for a refund
                                    </div>
                                    {refund ? <div style={{ marginTop: '15px' }}>
                                        <div style={{ display: "flex", alignItems: "center" }}><input
                                            type="radio"
                                            name="departmentFunds"
                                            value='true'
                                            checked={departmentFunds === 'true'}
                                            onChange={this.handleChange}
                                        /> Funds are managed by the Department
                                        </div>
                                        <br />
                                        <div style={{ display: "flex", alignItems: "center" }}><input
                                            type="radio"
                                            name="departmentFunds"
                                            value='false'
                                            checked={departmentFunds === 'false'}
                                            onChange={this.handleChange}
                                        /> Funds are managed by other entities
                                        </div>
                                        <br />

                                        {departmentFunds === 'true' ? <div>
                                            <label>Funds title</label>
                                            <input
                                                type="text"
                                                name="fundsTitle"
                                                placeholder="Funds title"
                                                onChange={this.handleChange}
                                                value={this.state.fundsTitle}
                                                required
                                            />
                                            <br /><br />
                                            <label>Refund amount</label>
                                            <CurrencyInput
                                                value={this.state.refundAmount}
                                                onChangeEvent={this.handleMoney}
                                                prefix="€ "
                                                decimalSeparator=","
                                                thousandSeparator="."
                                            />
                                            <br /><br />
                                            <div style={{ display: "flex", alignItems: "center" }}><input
                                                name="fundsOwner"
                                                type="checkbox"
                                                checked={this.state.fundsOwner}
                                                onChange={this.handleChange}
                                            /> I am the owner of the funds basement
                                            </div>
                                        </div> : null}

                                        {fundsOwner ? null :
                                            <div>
                                                <br />
                                                <label>Funds owner name</label>
                                                <input
                                                    type="text"
                                                    name="fundsOwnerName"
                                                    placeholder="Funds owner name"
                                                    onChange={this.handleChange}
                                                    value={this.state.fundsOwnerName}
                                                    required
                                                />
                                            </div>}

                                    </div> : null}
                                </div>
                            </div>

                            <div style={{ border: '2px solid #ccc', borderRadius: '10px', marginTop: '10px', padding: '10px' }}>
                                <center><h4>Means of transport</h4></center><hr /><br />
                                <div>
                                    <select name="meansOfTransport" value={this.state.meansOfTransport} onChange={this.handleChange}>
                                        <option value="plane">Plane</option>
                                        <option value="train">Train</option>
                                        <option value="bus">Bus</option>
                                        <option value="car">Car</option>
                                        <option value="motorcycle">Motorcycle</option>
                                    </select>
                                    <br /><br />
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <input
                                            name="transportOwner"
                                            type="checkbox"
                                            checked={this.state.transportOwner}
                                            onChange={this.handleChange}
                                            style={{ width: "25px", height: "25px" }}
                                        /> I am the owner of the means of transport
                                    </div>
                                    {transportOwner ? <div>
                                        <br />
                                        <label>Model</label>
                                        <input
                                            type="text"
                                            name="model"
                                            placeholder="Model of the vehicle"
                                            onChange={this.handleChange}
                                            value={this.state.model}
                                            required
                                        />
                                        <br />
                                        <label>License plate</label>
                                        <input
                                            type="text"
                                            name="licensePlate"
                                            placeholder="License plate number"
                                            onChange={this.handleChange}
                                            value={this.state.licensePlate}
                                            required
                                        />
                                        <br /><br />
                                        <label>Insurance Company</label>
                                        <input
                                            type="text"
                                            name="insuranceCompany"
                                            placeholder="Insurance company name"
                                            onChange={this.handleChange}
                                            value={this.state.insuranceCompany}
                                            required
                                        />
                                        <br />
                                        <label>Insurance Number</label>
                                        <input
                                            type="text"
                                            name="insuranceNumber"
                                            placeholder="Insurance company number"
                                            onChange={this.handleChange}
                                            value={this.state.insuranceNumber}
                                            required
                                        />
                                        <br />
                                        <label>Validity Period</label>
                                        <div style={{ display: 'flex', flexWrap: 'wrap' }} >
                                            <DatePicker
                                                name="startInsurance"
                                                placeholderText="Date of start"
                                                selected={this.state.startInsurance}
                                                onChange={date => this.changeDate('startInsurance', date)}
                                                customInput={<input type="text" />}
                                                dateFormat="dd/MM/yyyy"
                                            />
                                            <DatePicker
                                                name="endInsurance"
                                                placeholderText="Date of end"
                                                selected={this.state.endInsurance}
                                                onChange={date => this.changeDate('endInsurance', date)}
                                                customInput={<input type="text" />}
                                                dateFormat="dd/MM/yyyy"
                                            />
                                        </div>
                                    </div> : null}
                                </div>
                            </div>
                        </div>

                    </div>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <SaveButton title="Build" status={this.state.status} />
                    </div>

                    {link ?
                        // eslint-disable-next-line
                        <a target='_blank' rel="noopener noreferrer" href={link}>LINK NEXTCLOUD</a>
                        : null}
                </form>
            </div>
        )
    }
}

const formStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    width: '90%',
    padding: '40px 5% 40px 5%',
}


export default withRouter(MissionRequest);