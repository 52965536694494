import React, {Component} from 'react'
import { withRouter } from 'react-router-dom';
import SimpleSection from '@common/Section/SimpleSection'
import Section from '@common/Section'
import colors from '@utils/colors'
import {notify} from '@utils/notifications'
import Form from '../NewNode/Form'
import OptionalForm from '../NewNode/OptionalForm'
import config from '@utils/config'

class EditNode extends Component {
    constructor(props) {
        super(props);
        this.editor = React.createRef();
    }

    state = {
        id: window.location.pathname.split("edit/")[1],
        title: "",
        body: "",
        category: "news",
        description: "",
        tags: [],
        slug: "",
        slideshow: false,
        newfile: false,
        loading: true,
        shortTitle: "",
        place: "",
        date: "",
        link: ""
    }

    componentDidMount() {
        let url = config.serverUrl + "/api/node/id/" + this.state.id;
        this.setState({loading: true});
        fetch( url )
            .then(response => response.json())
            .then(data => {
                this.setState({
                    loading: false,
                    title: data.title,
                    body: data.body || "",
                    category: data.category,
                    description: data.description || "",
                    tags: data.tags || [],
                    link: data.link || "",
                    slug: data.slug || "",
                    oldTags: data.tags || [],
                    slideshow: (data.slideshow === 'true'),
                    image: (config.serverUrl+data.image) || undefined,
                    shortTitle: data.shortTitle || "",
                    date: data.date || "",
                    place: data.place || ""
                })
                this.editor.current.updateBody();
            })
    }

    updateState = ( newState) => {
        this.setState( newState )
    }

    submit = (event) => {
        let data = new FormData(),
            id = sessionStorage.getItem('id'),
            name = sessionStorage.getItem('name'),
            surname = sessionStorage.getItem('surname'),
            oldTags = this.state.oldTags,
            tags = this.state.tags,
            slug = this.state.slug,
            newTags,
            deletedTags,
            { shortTitle, place, date, link } = this.state;

        newTags = tags.filter( x => !oldTags.includes(x) ) || [];
        deletedTags = oldTags.filter( x => !tags.includes(x) ) || [];
        event.preventDefault();

        if(this.state.slug == "") {
            notify("Error", "Slug is required", "warning", "top-center");
            return;
        }


        data.append('title', this.state.title)
        data.append('body', this.state.body)
        data.append('category', this.state.category)
        data.append('description', this.state.description)
        data.append('tags', tags)
        data.append('slideshow', this.state.slideshow) 
        data.append('idUser', id )
        data.append('name', name )
        data.append('slug', slug )
        data.append('link', link )
        data.append('surname', surname )
        data.append('newTags', JSON.stringify(newTags))
        data.append('deletedTags', JSON.stringify(deletedTags))

        if(this.state.category === "call"){
            if(!(shortTitle)) {
                notify("Error", "Short title field is required", "warning", "top-center");
                return;
            }
            data.append('shortTitle', shortTitle);
            data.append('place', place);
            data.append('date', date);
        }

        if(this.state.slug == "") {
            notify("Error", "Slug is required", "warning", "top-center");
            return;
        }

        if(this.state.newfile)
            data.append('image', this.state.blob)

        fetch(config.serverUrl + '/api/node/'+this.state.id, {
            method: 'PATCH',
            headers: {'access-token': sessionStorage.getItem('access_token')},
            body: data
        })
        .then( response => response.json() )
        .then( (res) => {
            if(res.status === 200)
            {
                notify("Success!", res.body, "success", "top-center");
                this.props.history.push('/news/'+this.state.slug)
            }
            else
                notify("Error!", res.body, "warning", "top-center");
        })
        .catch( error => {console.log("ERR: " + JSON.stringify(error)); notify("Error", JSON.stringify(error), "warning", "top-center")} )
    }

    deleteNode = (event) => {
        event.preventDefault();
        let ok = window.confirm("Are you sure you want to delete this node?");
        if (ok)
        {
            fetch(config.serverUrl + '/api/node/'+this.state.id, {
                method: 'DELETE',
                headers: {'access-token': sessionStorage.getItem('access_token')}
            })
            .then( response => response.json() )
            .then( (res) => {
                if(res.status === 200)
                {
                    notify("Success!", res.body, "success", "top-center");
                    this.props.history.push('/news/')
                }
                else
                    notify("Error!", res.body, "warning", "top-center");
            })
            .catch( error => {console.log("ERR: " + JSON.stringify(error)); notify("Error", JSON.stringify(error), "warning", "top-center")} )
        }
    }

    render() {
        return (
            <div style={{top: "200px", display: "flex", flexDirection: "column" }}>
                    <form onSubmit={e => { e.preventDefault(); }}>
                    <Section 
                        content={[

                            {content: 
                                <SimpleSection 
                                    content={[
                                        {content:<Form state={this.state} updateState={this.updateState} ref={this.editor} />, flex: "1", minWidth: "200px"}
                                    ]} sep="0px"
                                />, title: "Update node", flex: "3", minWidth: "300px"
                            },

                            {content: 
                                <SimpleSection 
                                    content={[
                                        {content: <OptionalForm state={this.state} updateState={this.updateState} />, title: "Optional", flex: "1", minWidth: "300px"}
                                    ]} sep="0px"
                                />, title: "(Optional)", flex: "1", minWidth: "300px"
                            }
                        ]} 
                    />

                    <div><hr />
                        <div style={divBtnStyle}>
                            <button style={btnStyle} onClick={this.submit}>Submit</button>
                            <button style={btnStyle} onClick={e => { e.preventDefault(); this.props.history.push('/news/') }}>Cancel</button>
                            <button style={{...btnStyle, background: '#b20000'}} onClick={this.deleteNode}>Delete</button>
                        </div>
                        <hr />
                    </div>
                </form>
            </div>
        )
    }
}

const divBtnStyle = {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap"
}

const btnStyle = {
    width: "200px",
    height: "35px",
    margin: "1px 10px 1px 0",
    background: colors.color2,
    border: "none",
    borderRadius: "2px",
    color: colors.color5,
    fontWeight: "bold",
    cursor: "pointer"
}

export default withRouter(EditNode)
