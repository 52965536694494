import React, {Component} from "react"
import Section from "@common/Section"
import Socials from "@common/Socials"
import { ContextConsumer } from '@utils/Context'
import SimpleSection from "@common/Section/SimpleSection"
import Actions from "@common/Actions"
import NewsList from "./NewsList"

class News extends Component {
    state = {

    }

    render() {
        return (
            <div>
                <ContextConsumer>
                {
                    ({ isLogged, isAdmin }) => (
                        (isLogged && isAdmin) ?
                        <SimpleSection 
                                    content={[
                                        {
                                            content: <Actions content={[
                                                {link:"/news/newnode", title:"New Page"}
                                            ]} />, 
                                            flex: "1", 
                                            minWidth: "200px"
                                        }
                                    ]}
                        />
                        : null
                    )
                }
                </ContextConsumer>
                <Section content={[
                    {content: <NewsList />, title: "News", flex: "2", minWidth: "300px"},
                    {content: <Socials key="1" />, title: "", flex: "1", minWidth: "300px"}
                ]} />
            </div>
        )
    }
}

export default News
