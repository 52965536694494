import React from 'react'
import PropTypes from 'prop-types'
import SectionComponent from './SectionComponent'
import Separator from './Separator'

function Section(props) {
    let sections = []
    props.content.map( (item,index) => {
        if(index > 0)
            sections.push(<Separator key={props.content+index} width={props.sep} hideOnMobile={true} />)
        sections.push( <SectionComponent key={index} content={item.content} title={item.title} flex={item.flex} minWidth={item.minWidth} /> )
        return null
    })
    if(props.width) divStyle.width = props.width
    if(props.maxWidth) divStyle.maxWidth = props.maxWidth
    return (
        <div style={divStyle} >
                {sections}
        </div>
    )
}

var divStyle = {
    width: '95%',
    maxWidth: '1300px',
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    flex: '1'
}

Section.propTypes = {
    content: PropTypes.array,
    sep: PropTypes.string
};

Section.defaultProps = {
    sep: '30px'
};

export default Section