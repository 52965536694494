import React from 'react'
import colors from '@utils/colors'

function Topic(props) {
    return (
        <div style={topicStyle}>
            <div>
                <i 
                    className={"fa fa-circle fa-stack-1x text-primary"} 
                    style={{fontSize: '120px', color: colors.color3, position: 'relative', zIndex:'0'}} />
                <i 
                    className={'fa fa-'+props.type+' fa-stack-1x' } 
                    style={{fontSize: '60px', color: colors.color5, position: 'relative', top: '-86px',  zIndex:'0'}}
                />
                 <center><h4 style={{ position: 'relative', top: '-80px'}}> { props.title } </h4></center>
            </div>
           
        </div>
    )
}

const topicStyle = {
    width: '230px',
    height: '150px',
    padding: '20px'
}

export default Topic