import React, {Component} from 'react'
import { Link } from 'react-router-dom';
import colors from '@utils/colors'

class Item extends Component {
    render() {
        let currentStyle = this.props.selected ? selectedStyle : {}
        return (
            <div>{ this.props.selected ? 
               <Link style={{textDecoration: 'none'}} to={this.props.link}>
                  <div style={{...itemStyle, ...currentStyle }} onClick={ () => this.props.handleClick(this.props.id) } >
                    <h4 style={textStyle} >{this.props.title}</h4>
                    <i 
                        style={{ paddingRight: "20px", color: '#66ffdd'}} 
                        className="fa fa-2x fa-angle-double-right  " 
                        title={'View more'} 
                    />
               </div>
           </Link>
           :
           <div style={{...itemStyle, ...currentStyle }} onClick={ () => this.props.handleClick(this.props.id) } >
                <h4 style={textStyle} >{this.props.title}</h4>
                <Link to={this.props.link}>
                    <i 
                        style={{ paddingRight: "20px", color: '#66ffdd'}} 
                        className="fa fa-2x fa-angle-double-right  " 
                        title={'View more'} 
                    />
                </Link>
            </div>}
           </div>
        )
    }
}

const itemStyle = {
    background: colors.color2,
    color: colors.color4,
    border: '1px solid black',
    cursor: 'pointer',
    height: '89px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '5px',
    margin: '3px 0px'
}

const selectedStyle = {
    background: colors.color1,
    color: colors.color3,
    border: '1px solid '+ colors.color3,
}

const textStyle = {
    flex: 1,
    lineHeight: '150%',
    textAlign: 'justify',
    fontSize: '11pt',
    padding: "0 20px"
}

export default Item
