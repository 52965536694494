import React, {Component} from 'react';
import colors from '@utils/colors.js'

import PreHeader from './PreHeader';
import Logo from './Logo';
import Title from './Title';
import Actions from './Actions';

class Header extends Component {
    state = {
        scrolling: false,
        fixedStyle: {}
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }
    
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = (event) => {
        if (window.scrollY <= 24 && this.state.scrolling === true) {
            this.setState({
                scrolling: false,
                fixedStyle: {}
            });
        }
        else if (window.scrollY >= 25 && this.state.scrolling !== true) {
            this.setState({
                scrolling: true,
                fixedStyle: fixedHeader
            });
        }
    }

    render() {
        return(
            <div>
                <PreHeader />
                <div style={hiddenHeaderStyle}>FCRLab Unime</div>
                <div style={{...headerStyle, ...this.state.fixedStyle }} >
                    <Logo />
                    <Title />
                    <Actions />
                </div>
            </div>
        )
    }
}

const fixedHeader = {
    position: 'fixed',
    top: '0px'
}

const headerStyle = {
    position: 'absolute',
    top: '25px',
    zIndex: 1,
    height: '50px',
    
    overflow: 'hidden',
    width: '100%',
    borderBottom: '1px solid ' + colors.color3,

    backgroundColor: colors.color1,
    color: colors.color5,
    
    display: "flex",
    alignItems: 'center',
    justifyContent: 'space-between'
}

const hiddenHeaderStyle = {
    height: '48px', 
    backgroundColor: colors.color1, 
    color: colors.color3,
    fontSize: '20px',
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
}
export default Header;