import React from 'react'

function Column(props) {
    return (
        <div style={{ width: "48%", minWidth: '250px' }}>
            {props.children}
        </div>
    )
}

export default Column;