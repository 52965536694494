import React from 'react'

function AskToSign(props) {
    let requiredSignatures = props.requiredSignatures.map((item, index) => <option key={index} value={item.userId}>{item.role}</option>)
    return (
        <div>
            <select multiple disabled>
                {requiredSignatures}
            </select>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <input
                    name="askToSign"
                    type="checkbox"
                    checked={props.askToSign}
                    onChange={props.handleChange}
                    style={{ width: "25px", height: "25px" }}
                />
                <p>I want ask them to sign</p>
            </div>
        </div >
    )
}

export default AskToSign