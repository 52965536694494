import React from 'react'
import colors from '@utils/colors'
import pattern from '../pattern.png'

function SectionHeader(props) {
    return (
        <div style={titleStyle}>
            {props.title}  
        </div>
    )
}

const titleStyle = {
    fontSize: '25px',
    fontWeight: 'bold',
    color: colors.color5,
    backgroundImage: `url(${pattern})`,
    backgroundRepeat: 'repeat',
    width: '100%',
    margin: 'auto',
    borderRadius: '5px 5px 0px 0px',
    padding: '4px 0px',
    display: 'flex',
    justifyContent: 'center',
    minHeight: '30px'
}

export default SectionHeader