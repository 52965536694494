import React, {Component} from 'react'
import Editor from './Editor'

class Form extends Component {

    constructor(props) {
        super(props);
        this.editor = React.createRef();
    }

    updateBody() {
        this.editor.current.updateBody();
    }

    handleChange = (event) => {
        const {name, value, type, checked} = event.target
        type === "checkbox" ? this.setState({ [name]: checked }) : this.props.updateState({ [name]: value })
    }

    changeTitle = (e) => {
        this.handleChange(e);
        if (this.props.state.slug.length === 0 || this.props.state.autoSlug) {
             this.props.updateState({slug: e.target.value.toLowerCase().replace(/[^a-zA-Z] /g, "-").replace(/ /g, "-")});
        }
    }

    render() {
        return (
            <div style={{textAlign: 'left', display: "flex", flexDirection: "column"}}>
                <label>Title*</label>
                <input 
                    name="title" 
                    type="text" 
                    onChange={this.changeTitle}
                    value={this.props.state.title} 
                    required 
                /><br />

                <label>Category*</label>
                <select name="category" value={this.props.state.category} onChange={this.handleChange}>
                    <option value="news">News</option>
                    <option value="challenge">Challenge</option>
                    <option value="call">Call</option>
                </select><br />

                <label>Body*</label><br />
                <Editor body={this.props.state.body} updateBody={this.props.updateState} ref={this.editor} />
                
                <br />
            </div>
        )
    }
}


export default Form
