import React, {Component} from 'react'
import colors from '@utils/colors'
import loader from './loader.gif'

class SaveButton extends Component {
    render() {
        let message,
            title = this.props.title || "Save";
        switch(this.props.status) {
            case "saved":
                message = <span style={{color: 'green', textDecoration: 'underline'}}>Saved!</span>;
                break;
            case "unsaved":
                message = <span style={{color: 'orange', textDecoration: 'underline'}}>Unsaved changes</span>;
                break;
            case "error":
                    message = <span style={{color: 'red', textDecoration: 'underline'}}>Error</span>;
                    break;
            case "loading":
                message = <img src={loader} width="30px" alt="loader" />;
                break;
            default:
                message = null;
        }
        return (
            <div style={{display: 'flex', alignItems: 'center'}}>
                <button style={btnStyle}>{title}</button>
                {message}
            </div>
        )
    }
}

const btnStyle = {
    width: "100px",
    height: "40px",
    margin: "20px 0px",
    background: colors.color2,
    border: "none",
    borderRadius: "5px",
    color: colors.color5,
    fontWeight: "bold",
    cursor: "pointer",
    marginRight: '20px'
}

export default SaveButton