import React, { Component } from 'react'
import { notify } from '@utils/notifications'
import colors from '@utils/colors'
import config from '@utils/config'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';


class Form extends Component {
    state = {
        user: '',
        ip: '',
        resource: '',
        status: '',
        method: '',
        role: '',
        startDate: '',
        endDate: '',
        showFilter: false
    }

    handleChange = (event) => {
        const { name, value, type, checked } = event.target
        type === "checkbox" ? this.setState({ [name]: checked }) : this.setState({ [name]: value })
    }

    changeDate = (key, date) => {
        this.setState({ [key]: date })
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.props.setLoading();
        this.getLogs(true);
        this.setState({ showFilter: false })
    }

    getLogs = (firstPage) => {
        let { user, ip, resource, status, method, role, startDate, endDate } = this.state,
            limit = this.props.limit,
            page = firstPage ? 1 : this.props.page,
            skip = (page - 1) * limit;

        let data = { user, ip, resource, method, role, status: parseInt(status) }

        for (let key in data)
            if (!data[key])
                delete data[key]

        if (startDate) data.startDate = startDate.toISOString();
        if (endDate) data.endDate = endDate.toISOString();

        fetch(config.serverUrl + '/api/logs?limit=' + limit + '&skip=' + skip, {
            method: 'POST',
            headers: { 'access-token': sessionStorage.getItem('access_token'), 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then((res) => {
                if (res.status === 200) {
                    this.props.setLogs(res.body)
                }
                else {
                    notify("Error!", res.body, "warning", "top-center");
                }
            })
            .catch(error => {
                notify("Error", JSON.stringify(error), "warning", "top-center")
            })
    }

    toggleFilter = () => {
        let state = this.state.showFilter;
        this.setState({ showFilter: !state })
    }

    componentDidMount() {
        this.getLogs();
    }

    render() {
        let { user, ip, resource, status, method, role, startDate, endDate, showFilter } = this.state;
        return (
            <div style={{ border: '1px solid #cccccc', borderRadius: '10px', padding: '20px', marginBottom: '20px' }}>
                    <div style={{
                        height: '40px', cursor: 'pointer', border: '1px solid #cccccc', borderRadius: '5px',
                        width: '100px', display: 'flex', justifyContent: 'center', alignItems: 'center', userSelect: 'none'
                    }}>
                        <i onClick={this.toggleFilter}>
                            {showFilter ? <i className="fa fa-caret-up"> Filters</i> : <i className="fa fa-caret-down"> Filters</i>}
                        </i>
                </div>
                {showFilter ?
                    <form onSubmit={this.handleSubmit}>
                        <div style={formStyle} >
                            <div style={divStyle}>
                                <label>User ID</label>
                                <input type='text' name='user' value={user} onChange={this.handleChange} />
                            </div>
                            <div style={divStyle}>
                                <label>Ip Address</label>
                                <input type='text' name='ip' value={ip} onChange={this.handleChange} />
                            </div>
                            <div style={divStyle}>
                                <label>Resource</label>
                                <input type='text' name='resource' value={resource} onChange={this.handleChange} />
                            </div>
                            <div style={divStyle}>
                                <label>Status Code</label>
                                <input type='text' name='status' value={status} onChange={this.handleChange} />
                            </div>
                            <div style={divStyle}>
                                <label>Method</label>
                                <select name='method' value={method} onChange={this.handleChange} style={{ minWidth: '190px' }}>
                                    <option value=''></option>
                                    <option value='GET'>GET</option>
                                    <option value='POST'>POST</option>
                                    <option value='PATCH'>PATCH</option>
                                    <option value='PUT'>PUT</option>
                                    <option value='DELETE'>DELETE</option>
                                </select>
                            </div>
                            <div style={divStyle}>
                                <label>Role</label>
                                <input type='text' name='role' value={role} onChange={this.handleChange} />
                            </div>
                            <div style={{ display: 'flex', flexWrap: 'wrap' }} >
                                <div style={divStyle}>
                                    <label>From Date</label>
                                    <DatePicker
                                        name="startDate"
                                        placeholderText="dd/MM/yyyy"
                                        selected={startDate}
                                        onChange={date => this.changeDate('startDate', date)}
                                        customInput={<input type="text" />}
                                        dateFormat="dd/MM/yyyy"
                                    />
                                </div>
                                <div style={divStyle}>
                                    <label>To Date</label>
                                    <DatePicker
                                        name="endDate"
                                        placeholderText="dd/MM/yyyy"
                                        selected={endDate}
                                        onChange={date => this.changeDate('endDate', date)}
                                        customInput={<input type="text" />}
                                        dateFormat="dd/MM/yyyy"
                                    />
                                </div>
                            </div>
                        </div >
                        <button style={btnStyle}>Filter</button>
                    </form >
                    : null
                }
            </div>
        )
    }
}


const formStyle = {
    display: 'flex',
    alignItems: 'left',
    width: '100%',
    flexWrap: 'wrap',
    marginTop: '20px'
}

const divStyle = {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '150px',
    marginRight: '15px'
}

const btnStyle = {
    width: "100px",
    height: "40px",
    margin: "20px 0px",
    background: colors.color2,
    border: "none",
    borderRadius: "5px",
    color: colors.color5,
    fontWeight: "bold",
    cursor: "pointer"
}


export default Form