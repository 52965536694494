import React, {Component} from 'react'
import { withRouter } from 'react-router-dom';
import SimpleSection from '@common/Section/SimpleSection'
import Section from '@common/Section'
import colors from '@utils/colors'
import {notify} from '@utils/notifications'
import Form from './Form'
import OptionalForm from './OptionalForm'
import config from '@utils/config'

class NewNode extends Component {
    state = {
        title: "",
        body: "",
        category: "news",
        slug: "",
        description: "",
        tags: "",
        slideshow: false,
        newfile: false,
        shortTitle: "",
        place: "",
        date: "",
        link: "",
        autoSlug: true
    }

    updateState = ( newState) => {
        this.setState( newState )
    }

    submit = (event) => {
        let data = new FormData(),
            id = sessionStorage.getItem('id'),
            name = sessionStorage.getItem('name'),
            surname = sessionStorage.getItem('surname'),
            { shortTitle, place, date } = this.state;

        event.preventDefault();

        data.append('title', this.state.title)
        data.append('body', this.state.body)
        data.append('category', this.state.category)
        data.append('slideshow', this.state.slideshow) 
        data.append('idUser', id )
        data.append('name', name )
        data.append('surname', surname )
        data.append('slug', this.state.slug)
        data.append('link', this.state.link)

        if(this.state.description) data.append('description', this.state.description)
        if(this.state.tags) data.append('tags', this.state.tags)

        if(this.state.newfile)
            data.append('image', this.state.blob)
        if(this.state.category === "call"){
            if(!(shortTitle)) {
                notify("Error", "Short title field is required", "warning", "top-center");
                return;
            }
            data.append('shortTitle', shortTitle);
            data.append('place', place);
            data.append('date', date);
        }
        if(this.state.slug == "") {
            notify("Error", "Slug is required", "warning", "top-center");
            return;
        }

        fetch(config.serverUrl + '/api/node', {
            method: 'POST',
            headers: {'access-token': sessionStorage.getItem('access_token')},
            body: data
        })
        .then( response => response.json() )
        .then( (res) => {
            if(res.status === 201)
            {
                console.log(JSON.stringify(res))
                notify("Success!", res.body, "success", "top-center");
                this.props.history.push('/news/'+this.state.slug)
            }
            else
                notify("Error!", res.body, "warning", "top-center");
        })
        .catch( error => {console.log("ERR: " + JSON.stringify(error)); notify("Error", JSON.stringify(error), "warning", "top-center")} )
    }

    render() {
        return (
            <div style={{top: "200px", display: "flex", flexDirection: "column" }}>
                    <form onSubmit={e => { e.preventDefault(); }}>
                    <Section 
                        content={[

                            {content: 
                                <SimpleSection 
                                    content={[
                                        {content:<Form state={this.state} updateState={this.updateState} />, flex: "1", minWidth: "200px"}
                                    ]} sep="0px"
                                />, title: "Create new node", flex: "3", minWidth: "300px"
                            },

                            {content: 
                                <SimpleSection 
                                    content={[
                                        {content: <OptionalForm state={this.state} updateState={this.updateState} />, title: "Optional", flex: "1", minWidth: "300px"}
                                    ]} sep="0px"
                                />, title: "(Optional)", flex: "1", minWidth: "300px"
                            }
                        ]} 
                    />

                    <div><hr />
                        <div style={divBtnStyle}>
                            <button style={btnStyle} onClick={this.submit}>Submit</button>
                            <button style={btnStyle}>Cancel</button>
                        </div>
                        <hr />
                    </div>
                </form>
            </div>
        )
    }
}

const divBtnStyle = {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap"
}

const btnStyle = {
    width: "200px",
    height: "35px",
    margin: "1px 10px 1px 0",
    background: colors.color2,
    border: "none",
    borderRadius: "2px",
    color: colors.color5,
    fontWeight: "bold",
    cursor: "pointer"
}

export default withRouter(NewNode)
