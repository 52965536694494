import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import config from '@utils/config'
import SaveButton from '@common/SaveButton'
import Loading from '@common/Loading'
import { notify } from '@utils/notifications'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import Form from '@common/Form'
import Column from '@common/Form/Column'
import FormSection from '@common/Form/FormSection'
import AskToSign from '@common/AskToSign'

class Reports extends Component {
    state = {
        title: "Dott.",
        name: "",
        surname: "",
        project: "",
        date: new Date(),
        signDate: new Date(),
        startDate: new Date(),
        endDate: new Date(),
        signPlace: "Messina",
        selected: 0,
        projectsList: [],
        link: "",
        askToSign: false
    }

    handleChange = (event) => {
        if (this.state.status !== 'unsaved')
            this.setState({ status: 'unsaved' });
        const { name, value, type, checked } = event.target
        type === "checkbox" ? this.setState({ [name]: checked }) : this.setState({ [name]: value })
        console.log(this.state.askToSign)
    }

    signChange = (event) => {
        if (this.state.status !== 'unsaved')
            this.setState({ status: 'unsaved' });
        const { name, value, type, checked } = event.target
        let sign = this.state.sign;
        sign[name] = type === "checkbox" ? checked : value;
        this.setState({ sign })
    }

    changeDate = (label, date) => {
        this.setState({ [label]: date })
    }

    handleSubmit = (event, fun) => {
        let data,
            reportInfo = {},
            i = this.state.selected,
            askToSign = this.state.askToSign,
            project = this.state.projectsList[i];
        event.preventDefault();
        reportInfo.title = this.state.title;
        reportInfo.name = this.state.name;
        reportInfo.surname = this.state.surname;
        reportInfo.searchDate = this.state.date.toLocaleDateString("en-CA", { month: "2-digit", year: "numeric" });
        reportInfo.signDate = this.state.signDate.toLocaleDateString("it-IT", { day: "2-digit", month: "2-digit", year: "numeric" });
        reportInfo.startDate = this.state.startDate.toLocaleDateString("it-IT", { day: "2-digit", month: "2-digit", year: "numeric" });
        reportInfo.endDate = this.state.endDate.toLocaleDateString("it-IT", { day: "2-digit", month: "2-digit", year: "numeric" });
        reportInfo.signPlace = this.state.signPlace;
        reportInfo.projectName = project.info.projectName;
        reportInfo.projectId = project.openprojectId;
        reportInfo.filesDir = project.nextcloud.home;
        reportInfo.templatesDir = project.documents.report.templatesDir;

        let reqSigns = project.documents.report.requiredSignatures.map(sign => {
            if(!sign.userId)
                sign.userId = sessionStorage.getItem('id')
            return sign
        });
        data = { reportInfo, signatures:  {askToSign, requiredSignatures: reqSigns}}
        this.setState({ status: 'loading' });

        fetch(config.serverUrl + '/api/services/build/report', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'access-token': sessionStorage.getItem('access_token')
            },
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then((res) => {
                console.log(res)
                if (res.status === 200) {
                    notify("Success!", "Report builded", "success", "top-center");
                    this.setState({
                        status: 'saved',
                        link: config.serverUrl + "/api/services/document?path=/" + res.body
                    });
                }
                else {
                    this.setState({ status: undefined });
                    notify("Warning!", res.body, "warning", "top-center");
                }
            })
            .catch(error => {
                this.setState({ status: 'error' });
                notify("Error", JSON.stringify(error), "warning", "top-center")
            })
    }

    componentDidMount() {
        let url = config.serverUrl + "/users/getUserInfo/" + sessionStorage.getItem('id') + "?fields=name,surname",
            name, surname,
            startDate = new Date();
        startDate.setMonth(this.state.startDate.getMonth() - 1);
        this.setState({ loading: true, startDate });
        fetch(url)
            .then(response => response.json())
            .then((data) => {
                url = config.serverUrl + "/api/services/getProjects?user=" + sessionStorage.getItem('id');
                name = data.name;
                surname = data.surname;
                return fetch(url, { headers: { 'access-token': sessionStorage.getItem('access_token') } })
            })
            .then(response => response.json())
            .then(data => {
                if (data) {
                    let date = new Date();
                    this.setState({
                        projectsList: data,
                        selected: 0,
                        name: name,
                        surname: surname,
                        date: date,
                        sign: {
                            signDocument: false,
                            passphrase: "",
                            requiredSignatures: []
                        }
                    })
                }
                this.setState({ loading: false });
            })
    }

    render() {
        let projectsList = this.state.projectsList,
            projects = projectsList.map((item, i) => <option key={i} value={i}>{item.openprojectId}</option>),
            link = this.state.link,
            selected = this.state.selected,
            loading = this.state.loading,
            date = this.state.date;

        if (loading)
            return <Loading />;
        if (projects.length === 0) {
            return (
                <div style={{ background: 'white', height: '100%', marginLeft: '5px' }}>
                    <form style={formStyle} onSubmit={this.handleSubmit}>
                        <h1>You don't belong to any project</h1>
                    </form>
                </div>
            )
        }

        return (
            <Form title="Report Generator" handleSubmit={this.handleSubmit}>
                <Column>
                    <FormSection title="Personal Info">
                        <label>Title</label>
                        <select name="title" value={this.state.title} onChange={this.handleChange}>
                            <option value="Dott.">Dott.</option>
                            <option value="Dott.ssa">Dott.ssa</option>
                            <option value="Ing.">Ing.</option>
                        </select>
                        <br /><br />
                        <label>Name</label>
                        <input
                            type="text"
                            name="name"
                            placeholder="Your name"
                            onChange={this.handleChange}
                            value={this.state.name}
                            required
                        />
                        <br /><br />
                        <label>Surname</label>
                        <input
                            type="text"
                            name="surname"
                            placeholder="Your surname"
                            onChange={this.handleChange}
                            value={this.state.surname}
                            required
                        />
                    </FormSection>
                    <FormSection title="Project">
                        <select name="selected" value={selected} onChange={this.handleChange}>
                            {projects}
                        </select>
                        <input type="text" value={projectsList[selected].openprojectId} disabled />
                        <label>Nextcloud files directory</label>
                        <input type="text" value={projectsList[selected].nextcloud.home + "/" + projectsList[selected].nextcloud.dirs.reports} disabled />
                        <label>Files pattern</label>
                        <input
                            type="text"
                            value={date.toLocaleDateString("en-CA", { month: "2-digit", year: "numeric" }).replace("-", "") + "_*" + this.state.surname.replace(/[^a-z0-9]+|\s+/gmi, "") + ".md"}
                            disabled
                        />
                    </FormSection>
                </Column>
                <Column>
                    <FormSection title="Document">
                        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-evenly' }}>
                            <div>
                                <label>Files search date</label><br />
                                <DatePicker
                                    name="date"
                                    placeholderText="Click to select a date"
                                    selected={date}
                                    onChange={date => this.changeDate('date', date)}
                                    customInput={<input type="text" />}
                                    dateFormat="yyyy-MM"
                                />
                                <br />
                            </div>
                            <div>
                                <label>Sign date</label><br />
                                <DatePicker
                                    name="signDate"
                                    placeholderText="Click to select a date"
                                    selected={this.state.signDate}
                                    onChange={date => this.changeDate('signDate', date)}
                                    customInput={<input type="text" />}
                                    dateFormat="dd/MM/yyyy"
                                />
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-evenly' }}>
                            <div>
                                <label>Start date</label><br />
                                <DatePicker
                                    name="startDate"
                                    placeholderText="Click to select a date"
                                    selected={this.state.startDate}
                                    onChange={date => this.changeDate('startDate', date)}
                                    customInput={<input type="text" />}
                                    dateFormat="dd/MM/yyyy"
                                />
                                <br />
                            </div>
                            <div>
                                <label>End date</label><br />
                                <DatePicker
                                    name="endDate"
                                    placeholderText="Click to select a date"
                                    selected={this.state.endDate}
                                    onChange={date => this.changeDate('endDate', date)}
                                    customInput={<input type="text" />}
                                    dateFormat="dd/MM/yyyy"
                                />
                            </div>
                        </div>

                        <br />
                        <label>Sign place</label>
                        <input type="text" name="signPlace" value={this.state.signPlace} onChange={this.handleChange} />
                    </FormSection>
                    { projectsList[selected].documents.report.requiredSignatures.length > 0 ?
                        <FormSection title="Signature">
                            <AskToSign
                                requiredSignatures={projectsList[selected].documents.report.requiredSignatures}
                                handleChange={this.handleChange}
                                askToSign={this.state.askToSign}
                            />
                        </FormSection>
                    : null }
                </Column>
                <SaveButton title="Build" status={this.state.status} />

                {link ?
                    // eslint-disable-next-line
                    <div>
                        <a target='_blank' rel="noopener noreferrer" href={link}>LINK DOCUMENT</a>
                    </div>
                    : null}
            </Form>
        )
    }
}

const formStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    width: '100%%',
    minWidth: '280px',
    maxWidth: '1000px',
    padding: '40px 5% 40px 5%',
}

export default withRouter(Reports);
