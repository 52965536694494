import React, {Component} from 'react'

class NotAuthorized extends Component {
    render() {
        return (
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <h1>You are not authorized to this section</h1>
            </div>
        ) 
    }
}

export default NotAuthorized