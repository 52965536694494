import React from 'react';
import { sortable } from 'react-sortable';
import DatePicker from 'react-datepicker';
import colors from '@utils/colors'

const roles = ["Full Professor", 'Assistant Professor', 'Research Associate', 'Researcher', 'Research Fellow', 'Post Doc Researcher', 'PHD',  'PHD Student', 'Collaborator', 'Scholarship Recipient', 'Master\'s Degree Student', 'Student', 'Senior Developer', 'Developer']

class Item extends React.Component {
    render() {
        let item = this.props.children,
            optionsRoles = roles.map((item, index) => <option key={index} value={item.replace(/ /g, "-")}>{item}</option>),
            { changeRole, changeDate, removeItem, userId } = this.props

        const props = Object.assign({}, this.props);
        delete props.userId;
        delete props.removeItem;
        delete props.changeRole;
        delete props.changeDate;

        return (
            <li {...props}>
                <div style={listStyle}>
                    <div style={{ marginBottom: '10px', width: "30%" }}>
                        {item.email}
                    </div>
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        <div >
                            <h4 style={{ margin: 0, minWidth: "200px" }}>Joining Date</h4>
                            <DatePicker
                                name="dateOfBirth"
                                placeholderText="Date of birth"
                                selected={new Date(item.joiningDate.split('/').reverse().join('/')) || new Date()}
                                onChange={date => changeDate(date, userId)}
                                customInput={<input type="text" />}
                                dateFormat="MM/yyyy"
                            />
                        </div>
                        <div>
                            <h4 style={{ margin: 0 }}>Role</h4>
                            <select style={{ minWidth: '200px' }} value={item.role.replace(" ", "-")} onChange={(e) => changeRole(e, userId)} >
                                {optionsRoles}
                            </select>
                        </div>
                    </div>
                    <div>
                        <button style={btnStyle} onClick={() => removeItem(userId)}>Remove</button>
                    </div>
                </div>
            </li>
        )
    }
}

const listStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: '1px solid #cccccc',
    padding: '15px 10px 10px 10px',
    cursor: 'pointer'
}

const btnStyle = {
    width: "80px",
    height: "40px",
    margin: "0",
    background: colors.color2,
    border: "none",
    borderRadius: "5px",
    color: colors.color5,
    fontWeight: "bold",
    cursor: "pointer"
}

var SortableItem = sortable(Item);
export default SortableItem
