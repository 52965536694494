import React from 'react'
import SectionHeader from './SectionHeader'
import SectionFooter from './SectionFooter'
import colors from '@utils/colors'

function SectionComponent(props) {
    return (
        <div style={{
                flex: props.flex,
                //maxWidth: '100%',
                minWidth: props.minWidth,
                margin: '30px 0px 20px 0px',
                textAlign: 'center'
        }}>
            <SectionHeader title={props.title} />
            <div style={{background: colors.color6}}>
                {props.content}
            </div>
            <SectionFooter />
        </div>
    )
}

export default SectionComponent