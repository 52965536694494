import React, { Component } from 'react'
import Loading from '@common/Loading'
import NotAuthorized from '@common/NotAuthorized'
import config from '@utils/config'

class ProtectedRoute extends Component {
    state = {
        loading: true,
        allowed: false,
    }

    componentDidMount() {
        let roles = { roles: this.props.roles };
        fetch(config.serverUrl + '/auth/roles', {
            method: 'POST',
            headers: {
                "access-token": sessionStorage.getItem('access_token'),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(roles)
        })
            .then(response => response.json())
            .then((res) => {
                if(res.status === 200)
                    this.setState({allowed: true, loading: false})
                else
                    this.setState({allowed: false, loading: false})
            })
    }

    render() {
        return (
            this.state.loading ?
                <Loading />
                :
                this.state.allowed ?
                    this.props.render
                    :
                    <NotAuthorized />

        )
    }
}

export default ProtectedRoute