import React from 'react'
import colors from '@utils/colors'

function Item(props) {
    return (
        <div style={divStyle}>
            <div>Signature {props.id}</div>
            <div>Version {props.item.version}</div>
            <div>
                <button style={btnStyle} onClick={(event) => props.preview(event, props.id) }>Preview</button>
{/*                 <button style={btnStyle} onClick={(event) => props.delete(event, props.id) }>Delete</button> */}
            </div>
        </div>
    )
}
export default Item

const divStyle = {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    flexWrap: 'wrap',
    height: '60px',
    border: '1px solid #333333',
    width: '100%'
}

const btnStyle = {
    maxWidth: '250px',
    height: "30px",
    background: colors.color2,
    border: "none",
    borderRadius: "5px",
    color: colors.color5,
    fontWeight: "bold",
    cursor: "pointer",
    marginRight: '20px'
}
