import React, { Component } from 'react'
import ItemsCarousel from 'react-items-carousel';
import styled from 'styled-components';
import colors from '@utils/colors'
import Item from './Item'

const myProjects = [
    {
        id: 1,
        imgUrl: "/uploads/projects/muovime.jpg",
        title: "Muovime",
        link: "/project/muovime",
    },
    {
        id: 2,
        imgUrl: "/uploads/projects/goforit.jpg",
        title: "Goforit",
        link: "/project/GOFORIT",
    },
    {
        id: 1,
        imgUrl: "/uploads/projects/whatsbook.jpg",
        title: "WhatsBook",
        link: "/project/whatsbook",
    },
    {
        id: 4,
        imgUrl: "/uploads/projects/seefromsea.jpg",
        title: "SeeFromSea",
        link: "/project/seefromsea",
    },
    {
        id: 1,
        imgUrl: "/uploads/projects/muovime.jpg",
        title: "Muovime",
        link: "/project/muovime",
    }
]


const noOfItems = 6;
const autoPlayDelay = 2000;
const chevronWidth = 50;

const Wrapper = styled.div`
  padding: 0 ${chevronWidth}px;
  max-width: 1300px;
  margin: 0 auto;
`;

class Projects extends Component {
    state = {
        activeItemIndex: 0,
        noOfCards: Math.min(Math.floor(window.innerWidth / 600 + 1), 4),
        items: myProjects
    }

    componentDidMount() {
        this.interval = setInterval(this.tick, autoPlayDelay);
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
        clearInterval(this.interval);
    }

    tick = () => this.setState(prevState => ({
        activeItemIndex: (prevState.activeItemIndex + 1) % (noOfItems - this.state.noOfCards + 1),
    }));

    onChange = value => this.setState({ activeItemIndex: value });

    updateWindowDimensions = () => {
        this.setState({ noOfCards: Math.min(Math.floor(window.innerWidth / 500 + 1), 4) });
    }

    render() {
        let items = this.state.items.map(item => {
            return <Item key={item.id} title={item.title} link={item.link} imgUrl={item.imgUrl} />
        })
        return (
            <Wrapper>
                <ItemsCarousel
                    gutter={12}
                    numberOfCards={this.state.noOfCards}
                    activeItemIndex={this.state.activeItemIndex}
                    requestToChangeActive={this.onChange}
                    rightChevron={<button style={btnStyle}><i className="fa fa-4x fa-angle-right" /></button>}
                    leftChevron={<button style={btnStyle}><i className="fa fa-4x fa-angle-left" /></button>}
                    chevronWidth={chevronWidth}
                    outsideChevron
                    children={items}
                />
            </Wrapper>
        )
    }
}

const btnStyle = {
    background: "none",
    border: "none",
    borderRadius: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "35px",
    height: "55px",
    cursor: "pointer",
    outline: "none",
    color: colors.color2,

}

export default Projects