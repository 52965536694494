import React from 'react'
/* import Notification from './Notification' */

import Menu from './Menu'

function Actions() {
    return(
        <div style={{display: 'flex', alignItems: 'center', paddingRight: '10px'}}>
{/*             <Notification /> */}
            <Menu />
        </div>
    )
}

export default Actions