import React, { Component } from 'react'
import config from '@utils/config'
import colors from '@utils/colors'
import Section from '@common/Section'
import UserInfo from './UserInfo'
import UserSocials from './UserSocials'
import UserSettings from './UserSettings'
import Loading from '@common/Loading'

class EditUser extends Component {
    state = {
        user: null,
        loading: true,
        page: 0
    }

    componentDidMount() {
        let id = window.location.pathname.split("edit/")[1],
            url = config.serverUrl + "/users/getUserInfo/" + id;

        this.setState({ loading: true });
        fetch(url)
            .then(response => response.json())
            .then(data => {
                this.setState({
                    user: data,
                    loading: false,
                    page: 0
                })
            })
    }

    render() {
        let user = this.state.user,
            loading = this.state.loading,
            page, title;

        if (!loading) {
            switch (this.state.page) {
                case 0: page = <UserInfo user={user} />; title = "Edit user info: " + user.email; break;
                case 1: page = <UserSocials user={user} />; title = "Edit user socials: " + user.email; break;
                case 2: page = <UserSettings user={user} />; title = "Edit user settings: " + user.email; break;
                default: page = <div>Page not found</div>; break;
            }
        }
        const content = loading ?
            [{ content: <Loading width="95%" height="400px" title="" />, title: "", flex: "3", minWidth: "200px" }]
            :
            [{ content: page, title, flex: "1", minWidth: "200px" }]
        return (
            <div>
                <div style={{ display: 'flex', justifyContent: 'center', flwxWrap: 'wrap' }}>
                    <button onClick={() => this.setState({ page: 0 })} style={btnStyle}>User Info</button>
                    <button onClick={() => this.setState({ page: 1 })} style={btnStyle}>User Socials</button>
                    <button onClick={() => this.setState({ page: 2 })} style={btnStyle}>User Settings</button>
                </div>
                <Section content={content} />
            </div>
        )
    }
}

const btnStyle = {
    width: "100px",
    height: "40px",
    margin: "20px 20px",
    background: colors.color2,
    border: "none",
    borderRadius: "5px",
    color: colors.color5,
    fontWeight: "bold",
    cursor: "pointer"
}

export default EditUser