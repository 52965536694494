import React, {Component} from "react"
import Section from "@common/Section"
import Socials from "@common/Socials"
import { ContextConsumer } from '@utils/Context'
import SimpleSection from "@common/Section/SimpleSection"
import Actions from "@common/Actions"
import NewsList from "./NewsList"

class News extends Component {
    state = {

    }

    render() {
        return (
            <div>
                <Section content={[
                    {content: <NewsList />, title: "Category: " + window.location.pathname.split("category/")[1], flex: "2", minWidth: "300px"},
                    {content: <Socials key="1" />, title: "", flex: "1", minWidth: "300px"}
                ]} />
            </div>
        )
    }
}

export default News
